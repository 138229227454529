import superstore from 'superstore-sync';

export function isAnonymousUser(document) {
	return !/FTSession_s=/.test(document.cookie);
}

export function getRedirectUrlForAnonymousUser(conceptId, contentId) {
	const encodedConceptId = encodeURI(conceptId);
	const encodedContentId = encodeURI(contentId);

	return `https://subs.ft.com/newsletters?conceptId=${encodedConceptId}&contentId=${encodedContentId}`;
}

export function createDismissPromoCallback(el, promoDismissedStorageKey) {
	return function () {
		superstore.local.set(promoDismissedStorageKey, 'true');
		el.classList.add('hidden');
	};
}
