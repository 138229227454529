import oTooltip from '@financial-times/o-tooltip';
import { broadcast } from 'n-ui-foundations';

function responseFromElement(element) {
	let answer = element.getAttribute('data-answer');

	if (element.getAttribute('data-answer-selector')) {
		answer = document.querySelector(
			element.getAttribute('data-answer-selector')
		).value;
	}

	return {
		question: element.getAttribute('data-question'),
		answer
	};
}

export default class Feedback {
	constructor(featureName, options = {}) {
		this.featureName = featureName;
		this.options = options;

		this.page = 0;

		this.finished = false;
		this.tooltip = null;
		const container = document.querySelector(`.${featureName}-feedback`);

		if (!container) {
			return;
		}

		this.elements = {
			container: container,
			trigger: container.querySelector(`.js-${featureName}-feedback__trigger`),
			pages: Array.from(container.querySelectorAll('[data-feedback-stage]'))
		};

		this.tooltip = new oTooltip(this.elements.trigger, {
			target: `${this.featureName}-feedback-target`,
			content: this.getPageContent(),
			toggleOnClick: true,
			position: 'below'
		});

		this.elements.tooltipContent =
			this.tooltip.tooltipEl.querySelector('.o-tooltip-content');

		this.addTooltipListeners();
		this.addTooltipClosedListener();
		this.addListeners();
	}

	getPageContent() {
		return this.elements.pages[this.page].innerHTML;
	}

	addTooltipListeners() {
		const responders = this.elements.container.querySelectorAll(
			'.js-feedback__responder'
		);
		responders.forEach((responder) => {
			responder.addEventListener('click', () => {
				broadcast(
					'oTracking.event',
					Object.assign(
						{
							category: this.featureName,
							action: 'feedback'
						},
						responseFromElement(responder)
					)
				);

				this.nextPageOrDone();
			});
		});
	}
	nextPageOrDone() {
		this.page += 1;

		if (this.page > this.elements.pages.length - 1) {
			this.finished = true;
			this.elements.container.classList.add('feedback--complete');
			this.tooltip.close();
		} else {
			this.elements.tooltipContent.innerHTML = this.getPageContent();
			this.tooltip.drawTooltip();
			// TODO: use delegation
			this.addTooltipListeners();
		}
	}
	addListeners() {
		this.elements.trigger.addEventListener('click', (e) => {
			e.preventDefault();
			if (this.options.onClick) {
				this.options.onClick(this.featureName);
			}
		});
	}
	addTooltipClosedListener() {
		//If feedback is closed without sending a comment, the process should start from the beginning
		this.tooltip.tooltipEl.addEventListener('oTooltip.close', () => {
			this.page = 0;
			this.elements.tooltipContent.innerHTML = this.getPageContent();
			this.addTooltipListeners();
		});
	}
	closeTooltip() {
		if (this.tooltip) {
			this.tooltip.close();
		}
	}
}
