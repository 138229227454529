import { broadcast } from 'n-ui-foundations';
import {
	recordCommentEngagement,
	getCommentEngagement
} from './comment-engagement';

const trackCommentsInteractions = () => {
	const commentJumpLinks = document.querySelectorAll(
		'[href="#comments-anchor"]'
	);
	document.addEventListener('oComments.postComment', () =>
		recordCommentEngagement('active')
	);
	document.addEventListener('oComments.replyComment', () =>
		recordCommentEngagement('active')
	);
	document.addEventListener('oComments.likeComment', () =>
		recordCommentEngagement('active')
	);

	Array.from(commentJumpLinks).forEach((el) => {
		el.addEventListener('click', () => recordCommentEngagement('active'));
	});
};

const trackCommentsInView = () => {
	if (window.IntersectionObserver) {
		const initialCommentEngagement = getCommentEngagement();
		const rumIndicatorEl = document.querySelector('.comments__rum-indicator');
		const rumObserver = new IntersectionObserver(
			([instance]) => {
				// beacuse, of late, chrome has started firing on page load with isIntersecting = false
				if (instance.isIntersecting) {
					broadcast('oTracking.event', {
						action: 'view',
						category: 'comments',
						context: {
							product: 'next',
							source: 'next-article',
							userEngagement: initialCommentEngagement
						}
					});
					recordCommentEngagement('passive');
					rumObserver.unobserve(rumIndicatorEl);
				}
			},
			{ rootMargin: '0px' }
		);
		rumObserver.observe(rumIndicatorEl);
	}
};

export function init() {
	trackCommentsInView();
	trackCommentsInteractions();
}

export const getInitialEngagement = getCommentEngagement();
