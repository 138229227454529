/**
 * @typedef {{
 *   name?: string;
 *   className?: string;
 * }} SlotConfig
 */

/**
 * @callback CreateSlot
 * @param {number} index
 * @param {SlotConfig} [config={}] config
 * @returns {Element} Ad slot
 */

/**
 * @type CreateSlot
 */
export function createOAdsSlot(index, config = {}) {
	const { name, className } = config;
	const slot = document.createElement('div');
	slot.className = className ? className : 'o-ads in-article-advert advert';

	Object.assign(slot.dataset, {
		oAdsName: name ? name : `mpu${index || ''}`,
		oAdsFormatsMedium:
			index === 3 ? 'false' : 'Leaderboard,Responsive,OneByOne',
		oAdsTargeting: `pos=mid${index || ''};`,
		ariaHidden: 'true',
		oAdsCenter: 'true',
		oAdsLabel: 'true',
		oAdsFormatsSmall: 'MediumRectangle,Responsive,OneByOne',
		oAdsFormatsLarge: 'false',
		oAdsFormatsExtra: 'false'
	});

	return slot;
}
