import Comments from '@financial-times/o-comments';

export default (element) => {
	Comments.init(element);

	const commentCountElements = document.querySelectorAll(
		'.article-comment-count'
	);
	for (let i = 0; i < commentCountElements.length; i++) {
		commentCountElements[i].style.visibility = 'visible';
	}
};
