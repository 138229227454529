/* eslint no-console: ["error", { allow: ["warn"] }] */
import * as Utils from '@financial-times/o-utils';
import { broadcast } from 'n-ui-foundations';

const eventMaker = () =>
	broadcast('article:scroll-progress', getArticleProgress(window.pageYOffset));
const throttledEventMaker = Utils.throttle(eventMaker, 500);

let articleNode;
let initialised;
let scrollListenersInitialised;
let getFiguresListenersInitialised;
let startSeen;
let endSeen;

let start;
let end;
let viewPortHeight;

const getFigures = Utils.throttle(() => {
	const scrollY = window.pageYOffset;
	const article = articleNode.getBoundingClientRect();
	viewPortHeight = window.innerHeight;
	start = article.top + scrollY;
	end = start + article.height;
	throttledEventMaker();
}, 500);

export const initListeners = () => {
	if (initialised) return;
	articleNode = document.querySelector('.js-article__content-body');
	initScrollListeners();
	initGetFiguresListeners();
	initialised = true;
};

export function getArticleProgress(currentY) {
	initGetFiguresListeners();
	const progress = {};

	progress.getPercentage = (point) =>
		point >= end
			? 100
			: point <= start || !point
			? 0
			: (100 / (end - start)) * (point - start);
	progress.startPercentage = progress.getPercentage(currentY);
	progress.midPercentage = progress.getPercentage(
		currentY + viewPortHeight / 2
	);
	progress.endPercentage = progress.getPercentage(currentY + viewPortHeight);
	progress.inViewPercentage = progress.endPercentage - progress.startPercentage;
	progress.isBelowViewPort = progress.startPercentage <= 0;
	progress.isAboveViewPort = progress.endPercentage >= 100;

	startSeen = progress.startHasBeenSeen =
		startSeen || (progress.endPercentage > 0 && progress.startPercentage <= 0);
	endSeen = progress.endHasBeenSeen =
		endSeen ||
		(progress.startPercentage < 100 && progress.endPercentage >= 100);
	return progress;
}

function initScrollListeners() {
	if (!articleNode) {
		console.warn('Article element not found');
		return;
	} else if (scrollListenersInitialised) {
		return;
	}

	scrollListenersInitialised = true;
	window.addEventListener('scroll', throttledEventMaker);
	eventMaker(); // Fire once before any scrolling has happened
}

function initGetFiguresListeners() {
	if (!articleNode) {
		console.warn('Article element not found');
		return;
	} else if (getFiguresListenersInitialised) {
		return;
	}

	getFiguresListenersInitialised = true;
	[
		'oExpander.expand', // For when you get an article with an o-expander in it
		'oExpander.collapse', // For when you get an article with an o-expander in it
		'oExpander.init', // For when you get an article with an o-expander in it
		'oAds.complete', // For when you get the big ad at the top lazy loading
		'resize' // For when you resize the viewport
	].map((eventName) => window.addEventListener(eventName, getFigures));
	getFigures(); // Fire one off to initialise the figures
}
