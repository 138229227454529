// Because ad dimensions are unknowable in advance we have to assume a
// height of 600px rather than use mpu.getBoundingClientRect().height;
const MPU_HEIGHT = 600;
const MPU_BUFFER = 600;
const IMG_BUFFER = 16;

export const dimensions = {
	mpuHeight: MPU_HEIGHT,
	mpuBuffer: MPU_BUFFER,
	imgBuffer: IMG_BUFFER,
	regionMarginTop: 32,
	regionMarginBottom: 16,
	slotHeight: MPU_HEIGHT + MPU_BUFFER
};
