/**
 * @typedef {import("@typings/app").ConfigMap } ConfigMap
 * @typedef {import('./utils/createOAdsSlot').CreateSlot} CreateSlot
 */

import { createOAdsSlot } from './utils/createOAdsSlot';

const config = {
	anonymous: {
		startAt: 1,
		offset: 3,
		maxAds: 4
	},
	regular: {
		startAt: 2,
		offset: 5,
		maxAds: 3
	}
};

/**
 *
 * @param {Element} paragraph
 */
function isCurrentParagraphBeforeParagraph(paragraph) {
	return paragraph.nextSibling && paragraph.nextSibling.nodeName === 'P';
}

/**
 * @param {ConfigMap} flags
 * @param {boolean} isUserLoggedIn
 */
export function getConfig(flags, isUserLoggedIn) {
	if (isUserLoggedIn) {
		return config.regular;
	}

	return config.anonymous;
}

/**
 *
 * @param {CreateSlot} createSlot
 * @returns
 */
export function insertSlotsFactory(createSlot) {
	/**
	 * @param {ConfigMap} flags
	 * @param {boolean} isUserLoggedIn
	 */
	return function insertSlots(flags, isUserLoggedIn) {
		const articleBody = document.querySelector(
			'[data-attribute=article-content-body]'
		);

		if (!articleBody) {
			// eslint-disable-next-line no-console
			return console.warn('No article body found for ad insertion');
		}

		const paragraphs = articleBody.querySelectorAll(':scope > p');
		const { maxAds, startAt, offset } = getConfig(flags, isUserLoggedIn);

		let insertedAds = 0;

		for (
			let index = startAt;
			index < paragraphs.length && insertedAds < maxAds;
			index++
		) {
			const currentParagraph = paragraphs[index];

			if (isCurrentParagraphBeforeParagraph(currentParagraph)) {
				currentParagraph.after(createSlot(insertedAds));
				insertedAds++;
				index += offset;
			}
		}

		articleBody.after(
			createSlot(insertedAds, {
				className:
					'o-ads advert in-article-advert o-ads--center o-ads--label-left',
				name: 'third-mpu'
			})
		);
	};
}

export const insertOAdsSlots = insertSlotsFactory(createOAdsSlot);
