import oShare from '@financial-times/o-share';
import * as formatPost from '../live-event/format-post';

function formatLiveBlogPosts() {
	const liveBlogPosts = document.querySelectorAll('.x-live-blog-post');

	Array.from(liveBlogPosts).forEach((post) => {
		new oShare(post.querySelector('[data-o-component="o-share"]'));
		formatPost.after(post);
	});
}

export function initLiveBlogPost() {
	formatLiveBlogPosts();
}
