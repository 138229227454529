import { h } from '@financial-times/x-engine';
import { presets, Teaser } from '@financial-times/x-teaser';
import NativeAd from '../ads/native-ad-onward-journey';

const getTeaserByType = ({ teaser, type, container, adsUsePG }) =>
	type === 'ad' ? (
		<NativeAd
			name="native_onward_journey"
			classNames="o-teaser"
			container={container}
			nativeFormat="stacked"
			adsUsePG={adsUsePG}
		/>
	) : (
		<Teaser
			{...teaser}
			{...presets.Small}
			showImage={true}
			imageSize="Medium"
			modifiers={['stacked']}
		/>
	);

export default ({
	title,
	items,
	trackingName,
	contentSelection,
	container,
	adsUsePG
}) => (
	<div
		className="onward-journey__content o-grid-container"
		data-trackable={`onward-journey | ${trackingName}`}
		data-trackable-context-contentSelection={contentSelection}
	>
		<h2 className="standalone-teaser-heading">{title}</h2>
		<ul className="onward-journey__items o-grid-row">
			{items.map(({ teaser, type }) => (
				<li
					key={teaser?.id || type}
					className="onward-journey__item"
					data-o-grid-colspan="12 M6 L3"
				>
					{getTeaserByType({ teaser, type, container, adsUsePG })}
				</li>
			))}
		</ul>
	</div>
);
