import superstore from 'superstore-sync';

const engagementKey = 'next.comments.engagement';
const isWithinNDays = (timestamp, n) => {
	return Date.now() - timestamp < 1000 * 60 * 60 * 24 * n;
};

export function getCommentEngagement() {
	let value = superstore.local.get(engagementKey);
	if (!value) {
		return 'none';
	}

	// if has actively sought aout/interacted with comments in the last 30 days
	if (value.active && isWithinNDays(value.active, 30)) {
		return 'active';
	}

	if (value.passive) {
		// if scrolled as far as comments once in the last 5 days or 4 times in the last month
		if (
			isWithinNDays(value.passive[0], 5) ||
			value.passive.filter((timestamp) => isWithinNDays(timestamp, 30)).length >
				3
		) {
			return 'passive';
		}
	}

	return 'previous';
}
export function recordCommentEngagement(state) {
	let value = superstore.local.get(engagementKey);
	value = value || { active: null, passive: [] };
	if (state === 'active') {
		value.active = Date.now();
	} else {
		value.passive.unshift(Date.now());
		if (value.passive.length > 10) {
			value.passive = value.passive.slice(0, 10);
		}
	}
	superstore.local.set(engagementKey, value);
}
