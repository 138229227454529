import customFlourishAnalytics from '@financial-times/flourish-receive-custom-analytics';
import core from '@financial-times/o-tracking/src/javascript/core';
import { merge } from '@financial-times/o-tracking/src/javascript/utils';
import { get } from '@financial-times/o-tracking/src/javascript/core/settings';

export default function trackCustomFlourishGraphics() {
	// Subscribe to messages containing click event / custom event data from custom flourish templates and send the data to spoor
	customFlourishAnalytics.init((data) => {
		// Merge the event data into the "parent" config data
		const config = merge(get('config'), data);
		core.track(config);
	});
}
