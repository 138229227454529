import superstore from 'superstore-sync';
import oTooltip from '@financial-times/o-tooltip';
import { broadcast } from 'n-ui-foundations';

const SHARE_TOOLTIP_STORAGE_KEY = 'enterprise-share-tooltip-seen-v3';

const shouldSeeTooltip = () => {
	if (!superstore.isPersisting()) {
		throw new Error('Storage is not persisting data');
	}

	const tooltipLocalStorage = superstore.local.get(SHARE_TOOLTIP_STORAGE_KEY);
	const today = new Date();
	const todayString = today.toLocaleString('en-GB', {
		year: 'numeric',
		month: 'numeric',
		day: 'numeric'
	});

	if (!tooltipLocalStorage) {
		const expireDate = new Date();
		expireDate.setDate(expireDate.getDate() + 3);

		superstore.local.set(SHARE_TOOLTIP_STORAGE_KEY, {
			seen: false,
			expireDate: expireDate.toLocaleString('en-GB', {
				year: 'numeric',
				month: 'numeric',
				day: 'numeric'
			})
		});

		return true;
	}

	if (
		tooltipLocalStorage &&
		tooltipLocalStorage.seen === false &&
		tooltipLocalStorage.expireDate === todayString
	) {
		tooltipLocalStorage.seen = true;
		superstore.local.set(SHARE_TOOLTIP_STORAGE_KEY, tooltipLocalStorage);
		return true;
	}

	return false;
};

const hideSharingTooltip = () => {
	const tooltipLocalStorage = superstore.local.get(SHARE_TOOLTIP_STORAGE_KEY);
	if (tooltipLocalStorage && tooltipLocalStorage.seen === false) {
		tooltipLocalStorage.seen = true;
		superstore.local.set(SHARE_TOOLTIP_STORAGE_KEY, tooltipLocalStorage);
		broadcast('enterprise-share-tooltip-hide');
	}
};

const renderTooltip = (/** @type {string} */ position) => {
	const tooltipElement = document.querySelector(
		`[data-enterprise-sharing-tooltip=${position}]`
	);

	const opts = {
		target: `share-button-${position}`,
		content: `<b>Share with a wider audience</b> You can share this article with anyone you want, whether they have an FT subscription or not.`,
		showOnConstruction: true,
		position: position === 'vertical' ? 'right' : 'below'
	};

	return new oTooltip(tooltipElement, opts);
};

const initSharingTooltip = () => {
	if (shouldSeeTooltip()) {
		const verticalTooltip = renderTooltip('vertical');
		const horizontalTooltip = renderTooltip('horizontal');

		document.addEventListener('enterprise-share-tooltip-hide', () => {
			verticalTooltip.close();
			horizontalTooltip.close();
		});

		document.addEventListener('scroll', () => {
			verticalTooltip.closeAfter(2);
			horizontalTooltip.closeAfter(2);
		});

		const shareButton = document.querySelector('[data-share-button]');
		if (shareButton) {
			shareButton.addEventListener('click', () => {
				hideSharingTooltip();
			});
		}
	}
};

export { initSharingTooltip, shouldSeeTooltip, hideSharingTooltip };
