import superstore from 'superstore-sync';
import instantAlertsPromo from './instant-alerts-promo';
import { createDismissPromoCallback } from '../utils/instant-alert-cta';

const handleClose = () => {
	const el = document.querySelector('.js-pre-body-promotional-banner');
	if (!el) return;

	const name = el.dataset.promoName;
	const dismissButton = el.querySelector('.o-message__close');
	const dismissedStorageKey = `${name}-promo-dismissed-key`;
	const dismiss = createDismissPromoCallback(el, dismissedStorageKey);

	const hasDismissed = superstore.local.get(dismissedStorageKey);
	if (hasDismissed) return dismiss();

	dismissButton.addEventListener('click', dismiss);
};

export default () => {
	instantAlertsPromo();
	handleClose();
};
