import { h, render } from 'preact';
import ProgressIndicator from './component';
import * as articleProgress from '../utils/article-progress';

const isLiveBlog = !!document.querySelector('article[data-live-blog-path]');
const model = {};

export default () => {
	if (document.getElementById('article-progress')) {
		articleProgress.initListeners();

		window.addEventListener(
			'article:scroll-progress',
			({ detail: { midPercentage } = {} } = {}) => {
				model.isRead = midPercentage === 100 || model.isRead;
				model.progress = midPercentage;
				renderComponent();
			}
		);

		renderComponent();
	}
};

function renderComponent() {
	if (!isLiveBlog) {
		render(
			<ProgressIndicator {...model} />,
			document.getElementById('article-progress')
		);
	}
}
