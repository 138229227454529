import * as nTracking from '@financial-times/n-tracking';

/**
 * Sends flourish load failure to spoor.
 * @param {object} data
 * @param {object} context
 */
export default function trackFailure(context) {
	let newContext = {
		rootContentType: context.contentType,
		rootContentId: context.contentId,
		content: {
			asset_type: context.appName,
			uuid: context.contentId
		},
		app: context.appName,
		url: window.location.href
	};

	let event = {
		category: 'flourishCharts-error',
		action: 'view',
		...newContext
	};

	nTracking.broadcast('oTracking.event', event);
}
