/* eslint no-console: ["error", { allow: ["warn", "error", "info"] }] */

/**
 * @typedef {import("@typings/app").ConfigMap } ConfigMap
 * @typedef {import("@typings/ads").Data } AdsData
 */

import { displayAds, getAdsData, adsUtils } from '@financial-times/ads-display';
import Comments from '@financial-times/o-comments';

import { initialiseRHR, contentElSelectors } from './right-hand-rail';
import addAdsWatchAdSlot from './watch';
import * as commentsRHR from './comments-rhr';
import { insertOAdsSlots } from './in-content/insertInContentAds';

/**
 *
 * @param {{
 *	appContext: ConfigMap;
 *	flags: ConfigMap;
 *	adsData: AdsData
 * }} props
 *
 * @returns {Promise<void>}
 */
async function initialiseAds({ appContext, flags, adsData }) {
	const rootId = adsUtils.getRootID();
	const appName = appContext.get('appName');
	const waitForMoat = flags.get('adsMoatTimeout');

	// Normal article page
	const displayAdsOptions = {
		rootId,
		appName,
		waitForMoat,
		abTestState: appContext.get('abTestState'),
		lazyLoadMargins: undefined
	};

	// Load targeted ads
	// TODO: pass both displayAdsOptions and enhancements as separate properties
	//			so that displayAds.init can perform this fallback internally
	try {
		const enhancedOptions = {
			...displayAdsOptions,
			adUnit: adsData.adUnit,
			targeting: adsData.metadata,
			smartmatch: adsData.smartmatch,
			formats: {
				ShareNav: {
					sizes: [40, 180]
				}
			}
		};
		await displayAds
			.init(enhancedOptions, flags)
			.catch((/** @type {string?} */ msg) => {
				if (msg) console.warn('displayAds.init threw a rejection', { msg });
			});
	} catch (error) {
		// Ensure that only genuine errors cause displayAds.init to be re-run
		if (typeof error !== 'undefined') {
			console.error('displayAds.init fallback', { error });
			await displayAds.init(displayAdsOptions, flags);
		}
	}

	// Enable permutive
	if (flags.get('AdsPermutive')) {
		try {
			adsUtils.enablePermutiveFtCom({
				metadata: adsData.metadata,
				type: appName,
				rootId
			});
		} catch (error) {
			console.warn(
				'adsUtils.enablePermutiveFtCom: Failed to load permutive correctly. Falling back to loading permutive without page or user data',
				error
			);

			adsUtils.enablePermutiveFtCom({ type: appName, rootId });
		}
	}
}

/**
 * @param {ConfigMap} appContext
 * @param {ConfigMap} flags
 */
export async function init(appContext, flags) {
	const isUserLoggedIn = Boolean(appContext.get('isUserLoggedIn'));

	insertOAdsSlots(flags, isUserLoggedIn);
	const initSlot = window.oAds.slots.initSlot.bind(window.oAds.slots);

	try {
		const contentId =
			appContext && appContext.get && appContext.get('contentId');
		const adsData = await getAdsData({
			user: true,
			page: { type: 'article', id: contentId }
		});

		if (flags.get('adsWatchSlot')) {
			addAdsWatchAdSlot();
		}

		// Initialise declarative ads (RHR ads are initialised dynamically)
		await initialiseAds({ appContext, flags, adsData });

		// Enable permutive when user provides consent in cookie banner
		// `displayAds` also needs to be initialised again to ensure user info is updated
		if (flags.get('AdsPermutive')) {
			document.addEventListener('oCookieMessage.act', async () => {
				// A small timeout is set as `oCookieMessage.act` is fired BEFORE consent request is made and cookie is updated
				setTimeout(async () => {
					await adsUtils.updateConsentInfo({
						updatePrivacyLegislation: false,
						updateGpcValue: false,
						updateConsentCookie: true
					});
					const updatedAdsData = await getAdsData({
						user: true,
						page: { type: 'article', id: contentId }
					});
					await initialiseAds({ appContext, flags, adsData: updatedAdsData });
				}, 1000);
			});
		}

		// Short term solution
		// Disable right hand rail ads on scrollytelling
		// We can add these back in once we improve the intersection detection
		const scrollytellingElements = document.querySelector(
			'[data-component="scrollytelling-image"]'
		);
		if (!scrollytellingElements) {
			// Initialise dynamic ads (RHR)
			/** @type {HTMLDivElement | null} */
			const contentEl = document.querySelector(contentElSelectors);
			initialiseRHR({
				initSlot,
				contentEl,
				isUserLoggedIn: Boolean(appContext.get('isUserLoggedIn')),
				flags
			});
		}

		// Enable Ads on a rail beside comments
		if (flags.get('adsCommentsRHR') === 'variant') {
			commentsRHR.init({ initSlot, Comments });
		}
	} catch (error) {
		console.warn('There was an error fetching the ads data.', error);
	}
}

export function backFillNativeAdSlot(nativeAdEl = {}, backfillEl = {}) {
	if (!backfillEl.innerHTML || !nativeAdEl.innerHTML) {
		return;
	}
	window.oAds.utils.on('slotExpand', (event) => {
		if (event && event.detail) {
			const adSlot = event.detail;
			if (adSlot.pos === 'native' && adSlot.isEmpty) {
				nativeAdEl.innerHTML = backfillEl.innerHTML;
			}
		}
	});
}
