// @ts-nocheck
import { getArticleId } from '../utils/article';
import { broadcast } from 'n-ui-foundations';
const cookies = require('js-cookie');

const isMobile = /Mobi/.test(navigator.userAgent);

const resizeAdditionalSignupIframe = () => {
	const container = document.querySelector(
		'.sign-up-additional-location-container'
	);
	if (container) {
		container.style.height = 'auto';
	}

	const headerHeight = document.querySelector(
		'header[id=site-navigation]'
	).clientHeight;
	const screenHeight = document.documentElement.clientHeight;

	// if the form height is longer than the screen, then resize it to be scrollable
	if (container.scrollHeight - headerHeight > screenHeight) {
		container.style.height = `${screenHeight - headerHeight}px`;
	}
};

const resizeSignUpIframe = (signUpIframe) => {
	const content =
		signUpIframe.contentWindow.document.getElementById('site-content');
	signUpIframe.height = `${content.scrollHeight}px`;
	if (isMobile) {
		resizeAdditionalSignupIframe();
	}
};

const ADDITIONAL_SIGNUP_CLOSED_COOKIE = 'additionalInArticleSignupClosed';
const ADDITIONAL_SIGNUP_LANDED_COOKIE = 'additionalInArticleSignupLanded';
const ONE_DAY_MS = 24 * 60 * 60 * 1000;

const setupAdditionalIframe = () => {
	const additionalSignupIframe = document.getElementById(
		'additionalSignupIframe'
	);

	if (additionalSignupIframe) {
		const signupButton = document.querySelector(
			'.o-message--sign-up-additional-location .o-message__actions__primary'
		);
		signupButton.addEventListener('click', (event) => {
			event.preventDefault();

			// show signup component
			document.querySelector(
				'.o-message .article__content-sign-up'
			).style.display = 'table';
			// hide the brief header
			document.querySelector(
				'.o-message--sign-up-additional-location .o-message__content-main'
			).style.display = 'none';

			resizeSignUpIframe(additionalSignupIframe);
			window.addEventListener('resize', () =>
				resizeSignUpIframe(additionalSignupIframe)
			);
		});

		const messageCloseButton = document.querySelector(
			'.o-message--sign-up-additional-location .o-message__close'
		);
		messageCloseButton.addEventListener('click', () => {
			document.querySelector(
				'.o-message--sign-up-additional-location'
			).style.display = 'none';
			const expiryDate = new Date(new Date().getTime() + ONE_DAY_MS);
			cookies.set(ADDITIONAL_SIGNUP_CLOSED_COOKIE, getArticleId(), {
				domain: 'ft.com',
				expires: expiryDate
			});
		});
	}
};

const addSignupDetailsListeners = () => {
	document.addEventListener('ft-in-article-sign-up-result', () => {
		const url = new URL(window.location.href);
		url.searchParams.append('signupConfirmation', 'success');
		window.location.replace(url.toString());
	});

	document.addEventListener('ft-in-article-sign-up-resize-iframe', () => {
		const signUpIframe = document.getElementById('signUpIframe');
		if (signUpIframe) {
			resizeSignUpIframe(signUpIframe);
		}
		const additionalSignUpIframe = document.getElementById(
			'additionalSignupIframe'
		);
		if (additionalSignUpIframe) {
			resizeSignUpIframe(additionalSignUpIframe);
		}
	});

	const signupIframe = document.getElementById('signUpIframe');
	if (signupIframe) {
		const intersectionObserverOptions = {
			root: null,
			rootMargin: '0px',
			threshold: 1.0
		};
		const observer = new IntersectionObserver(function (entries) {
			const additionalSignupElement = document.querySelector(
				'.o-message--sign-up-additional-location'
			);
			entries.forEach((entry) => {
				if (
					entry.intersectionRatio === intersectionObserverOptions.threshold &&
					additionalSignupElement
				) {
					// show signup component
					document.querySelector(
						'.o-message .article__content-sign-up'
					).style.display = 'none';
					// hide the brief header
					document.querySelector(
						'.o-message--sign-up-additional-location .o-message__content-main'
					).style.display = 'flex';

					additionalSignupElement.style.display = 'none';
				} else {
					// set container height to auto in case the user was interacting with the sticky banner before
					const container = document.querySelector(
						'.sign-up-additional-location-container'
					);
					if (container) {
						container.style.height = 'auto';
					}

					const distanceToTop = signupIframe.getBoundingClientRect().top;

					const articleId = getArticleId();
					// only show the additional signup box when we've scrolled past the original form and the close button has not been clicked
					const additionalLocationHasNotBeenClosed =
						cookies.get(ADDITIONAL_SIGNUP_CLOSED_COOKIE) !== articleId;
					if (
						distanceToTop <= 0 &&
						additionalLocationHasNotBeenClosed &&
						additionalSignupElement
					) {
						additionalSignupElement.style.display = 'block';
					}

					// there's a difference in height on the standard FT sticky header and the one on certain articles (like coronavirus: free to read)
					const isStandardStickyHeader = Boolean(
						document.querySelector('div[data-trackable="header-sticky"]')
					);
					if (!isStandardStickyHeader) {
						additionalSignupElement.style.top = '40px';
					}

					if (cookies.get(ADDITIONAL_SIGNUP_LANDED_COOKIE) !== articleId) {
						const expiryDate = new Date(new Date().getTime() + ONE_DAY_MS);
						cookies.set(ADDITIONAL_SIGNUP_LANDED_COOKIE, articleId, {
							domain: 'ft.com',
							expires: expiryDate
						});
						broadcast('oTracking.event', {
							action: 'landing',
							category: 'signup',
							app: 'subscribe',
							formType: 'next.register',
							type: 'sticky-banner'
						});
					}
				}
			});
		}, intersectionObserverOptions);
		observer.observe(signupIframe);
	}
};

// when the page is loaded from mobile, the scroll is adjusted up to the feature title
const focusToIframeOnClickWhenMobile = (signUpIframe) => {
	if (!isMobile) {
		return;
	}

	signUpIframe.contentDocument.addEventListener('click', () => {
		const signUpForm = document
			.querySelector('div.article__content  div.article__content-sign-up')
			.getBoundingClientRect();
		const headerHeight = document.querySelector(
			'header[id=site-navigation]'
		).offsetHeight;
		// the Y position is calculated by current window scroll and the main component position
		window.scrollTo(0, window.scrollY + signUpForm.y - headerHeight);
	});
};

const registerOnLoadHandler = () => {
	const signUpIframe = document.getElementById('signUpIframe');
	if (signUpIframe) {
		signUpIframe.onload = () => {
			resizeSignUpIframe(signUpIframe);
			focusToIframeOnClickWhenMobile(signUpIframe);
			window.addEventListener('resize', () => resizeSignUpIframe(signUpIframe));
		};
	}
	setupAdditionalIframe();
};

export { registerOnLoadHandler, addSignupDetailsListeners };
