import trackFailure from './track-failure';

/**
 * Replaces flourish chart images with interactive charts
 *
 * @param {{appContext: object}} appContext
 *
 */
export default function replaceWithFlourish(appContext) {
	if (!window.Flourish) {
		window.Flourish = { disable_autoload: true };
		const flourishScript = document.createElement('script');
		flourishScript.src = 'https://public.flourish.studio/resources/embed.js';
		flourishScript.defer = true;

		flourishScript.addEventListener('error', () => {
			trackFailure(appContext.appContext);
		});

		flourishScript.addEventListener('load', () => {
			document.body.dispatchEvent(
				new CustomEvent('FLOURISH_LOADED', {
					bubbles: true
				})
			);
			replaceAll();
		});
		// attach the script after we've added the event listeners
		document.body.appendChild(flourishScript);
	} else {
		replaceAll();
	}
}

function replaceAll() {
	const graphicsToReplace = document.querySelectorAll(
		'[data-asset-type="flourish"]'
	);
	graphicsToReplace.forEach((element) => {
		const attributes = element.attributes;
		const flourishIdAttrib = attributes.getNamedItem('data-flourish-id');
		const flourishType = attributes.getNamedItem('data-flourish-type')
			? attributes.getNamedItem('data-flourish-type').value
			: 'visualisation';
		const elementParent = element.parentElement;
		// .getAttribute('href') returns only the value of the attribute whereas .href would return the full resolved url path eg. ft.com/content/{uuid}#{flourishIdAttrib}
		const elementToReplace =
			elementParent &&
			elementParent.getAttribute('href') === `#${flourishIdAttrib.value}`
				? elementParent
				: element;

		if (flourishIdAttrib) {
			const flourishId = flourishIdAttrib.value;
			const replacement = document.createElement('div');
			replacement.className = 'flourish-embed';
			// query string of any value is required to hide the Flourish signature on the embedded graphic
			replacement.dataset.src = `${flourishType}/${flourishId}?hideSignature`;
			elementToReplace.replaceWith(replacement);
			window.Flourish.loadEmbed(replacement);
		}
	});
}
