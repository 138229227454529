import * as scrollytellingImage from '@financial-times/n-scrollytelling-image';

export default function scrollytelling(flags) {
	if (flags.get('scrollytellingImages')) {
		const articleBody = document.querySelector(
			'[data-component="article-body"]'
		);
		scrollytellingImage.init(articleBody);
	}
}
