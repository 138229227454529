/**
 * @param {{get: (key: string) => boolean}} flags
 */
export function adsUsePGClient(flags) {
	return adsUsePG({
		adsForcePG: flags.get('adsForcePG'),
		adsPGArticle: flags.get('adsPGArticle')
	});
}

/**
 * Determine whether to render ads in legacy oAds format or as <pg-slot> elements
 *
 * @param {{ adsForcePG: boolean, adsPGArticle: boolean }} props
 */
export function adsUsePG({ adsForcePG, adsPGArticle }) {
	return adsForcePG === true || adsPGArticle === true;
}
