/**
 * @typedef {import("@typings/right-hand-rail")} RightHandRail
 * @typedef {typeof import("./dimensions").dimensions} Dimensions
 */

/**
 * If `fullBleedEl` contains an image or a flourish component...
 *
 * EITHER:
 * Search for data attrs embedding the original dimensions and scale the height up in
 * proportion to the fullBleedEl's width.
 *
 * OR:
 * Return a DOMRect with a "safe" height of 880px. This can happen in the case of flourish content
 * that was both published before 2021-10-15 and has not yet been reingested via
 * https://ft-next-es-interface-eu.herokuapp.com/ui/reingest
 *
 * @param {HTMLElement} fullBleedEl
 * @returns {DOMRect}
 */
function getFullbleedRect(fullBleedEl) {
	const fullBleedRect = fullBleedEl.getBoundingClientRect();

	// Ordinarily images will have already loaded: return `fullBleedRect` if so
	/** @type {HTMLImageElement | null} */
	const imgEl = fullBleedEl.querySelector('img[data-image-type]');
	if (imgEl && imgEl.complete) {
		return fullBleedRect;
	}

	// Graphical content as-yet unloaded: find the original dimensions via data attribute
	/** @type {HTMLElement | null} */
	const container = fullBleedEl.querySelector('[data-original-image-height]');
	if (container) {
		// For Flourish content & images that are still loading, derive the height from embedded attributes
		// The fallback 1020x760 values give å safe height of 880px at a scrollWidth of 1180px;
		const originalWidth = parseInt(
			container.dataset.originalImageWidth || '1020',
			10
		);
		const originalHeight = parseInt(
			container.dataset.originalImageHeight || '760',
			10
		);
		const proportionalHeight =
			(container.scrollWidth / originalWidth) * originalHeight;

		fullBleedRect.height = proportionalHeight;
		return fullBleedRect;
	}

	// Non-graphical content (e.g. tables): use the reported dimensions as-is
	return fullBleedRect;
}

/**
 * @param {HTMLElement} contentEl
 * @param {string[]} selectors
 *
 * @returns {DOMRect[]}
 */
export function getIntersectingRects(contentEl, selectors) {
	/** @type {NodeListOf<HTMLElement>} */
	const fullBleedList = contentEl.querySelectorAll(selectors.join(','));
	const fullBleedRects = [];
	for (const el of fullBleedList) {
		fullBleedRects.push(getFullbleedRect(el));
	}

	return fullBleedRects;
}

/**
 * Return an array of ClientRects for intersecting images
 *
 * @param {{
 *	candidateRects: DOMRect[];
 *	targetRect: DOMRect;
 * }} props
 *
 * @returns {DOMRect[]}
 */
export function calcIntersections({ candidateRects, targetRect }) {
	/** @type {DOMRect[]} */
	const intersectingRects = [];

	for (const rect of candidateRects) {
		const isIntersecting = !(
			rect.top > targetRect.bottom || rect.bottom < targetRect.top
		);

		if (isIntersecting) {
			intersectingRects.push(rect);
		}
	}

	return intersectingRects;
}

/**
 * @param {{
 *	targetRect: DOMRect;
 *	dimensions: Dimensions
 * }} props
 *
 * @returns { RightHandRail.CalcAdRegion }
 */
function calcAdRegionFactory({ targetRect, dimensions }) {
	return function calcAdRegion({
		imgRect = { bottom: targetRect.top },
		nextRect = { top: targetRect.top },
		marginTop = dimensions.regionMarginTop,
		marginBottom = dimensions.regionMarginBottom
	}) {
		const top = imgRect.bottom - targetRect.top + marginTop;
		const bottom = nextRect.top - targetRect.top - marginBottom;

		return {
			top,
			bottom,
			height: bottom - top
		};
	};
}

/**
 * Calculate regions into which ads can be fitted between full-bleed images
 *
 * @param {{
 * 	imgRects: DOMRect[];     // Intersecting full-bleed element DOMRects
 *	targetRect: DOMRect;     // The slots' parent element
 *	dimensions: Dimensions;  // Constants like margins, buffers, etc
 * }} props
 *
 * @returns {RightHandRail.Rect[]}
 */
export function calcAdRegions({ imgRects, targetRect, dimensions }) {
	const calcAdRegion = calcAdRegionFactory({ targetRect, dimensions });

	// If no images intersect return a single region that spans the full targetRect.height
	if (imgRects.length === 0) {
		return [
			calcAdRegion({ marginTop: 0, nextRect: { top: targetRect.bottom } })
		];
	}

	const regions = [];

	// Pre-intersection region:
	// 0 intersections: full rail height
	// 1+ intersections: area above the first full-bleed image
	const preRegion = calcAdRegion({ marginTop: 0, nextRect: imgRects[0] });
	if (preRegion.height > 0) {
		regions.push(preRegion);
	}

	// Inter-intersection regions
	let nextIndex = 1;
	for (const imgRect of imgRects) {
		const nextRect = imgRects[nextIndex++];

		if (nextRect) {
			regions.push(calcAdRegion({ imgRect, nextRect }));
		}
	}

	// Post-intersection region:
	// Remaining railRect area after the last intersecting element
	const lastImgRect = imgRects[imgRects.length - 1];
	const postTargetRect = {
		top: targetRect.bottom,
		bottom: targetRect.bottom,
		height: 0
	};
	const postRegion = calcAdRegion({
		imgRect: lastImgRect,
		nextRect: postTargetRect,
		marginBottom: 0
	});
	if (postRegion.height > 0) {
		regions.push(postRegion);
	}

	return regions;
}

/**
 * Calculate the `top` value of as many slots as a region can fit
 *
 * @param {{
 *	regions: RightHandRail.Rect[];
 *	dimensions: Dimensions;
 * }} props
 *
 * @returns {number[]}
 */
export function calcSlotYs({ regions, dimensions }) {
	/** @type {number[]} */
	let slotYs = [];
	for (const region of regions) {
		// Add one mpuBuffer: the final ad in a region doesn't need to be buffered at its lower edge
		const adMaxHeight = region.height + dimensions.mpuBuffer;
		const regionSlotNum = Math.floor(adMaxHeight / dimensions.slotHeight);

		const regionYs = Array.from(
			{ length: regionSlotNum },
			(_, i) => region.top + i * dimensions.slotHeight
		);

		slotYs = slotYs.concat(regionYs);
	}

	return slotYs;
}

/**
 * Calculate the `top` value of slots based on position of live blog
 *
 * @param {{
 *	targetRect: DOMRect;
 *	liveblogRects: DOMRect[];
 *  blogIndexes: number[];  // index of the blogs you want to insert a slot after
 * }} props
 *
 * @returns {number[]}
 */
export function calcSlotYsLiveblog({ targetRect, liveblogRects, blogIndexes }) {
	return blogIndexes
		.map(
			(index) => liveblogRects[index] && liveblogRects[index].y - targetRect.y
		)
		.filter((y) => y);
}
