import * as myftUi from '@financial-times/n-myft-ui/myft';
import Expander from '@financial-times/o-expander';
import Video from '@financial-times/o-video';
import nClip from '@financial-times/n-clip';
import Clip  from '@financial-times/cp-content-pipeline-ui/lib/components/Clip/client';
import Audio from '@financial-times/o-audio';
import oDate from '@financial-times/o-date';
import { nMessagingClient } from '@financial-times/n-messaging-client/main-client';
import { magnetInit } from '@financial-times/n-magnet';
import { init as commentsInit } from './components/comments';
import instantAlertCta from './components/pre-body-promotional-banner/instant-alert-cta';
import initialiseEmbeddedTweets from './components/embedded-tweets';
import { init as onboardingTooltipInit } from './components/onboarding-tooltip';
import oErrors from '@financial-times/o-errors';

import * as onwardJourney from './components/onward-journey';
import componentTracking from './components/tracking/main';
import * as share from './components/share/main';
import legalCopy from './components/legal-copy/main';
import * as seriesStickyNav from './components/series-sticky-nav';
import Feedback from './components/feedback';
import progressIndicator from './components/progress-indicator';
import preBodyPromotionalBanner from './components/pre-body-promotional-banner/main';
import initTables from './components/table/main';
import { initLiveBlogPost } from './components/live-blog-post';
import * as printButton from './components/print-button';
import * as nExponea from '@financial-times/n-exponea';
import workspaceNavInit from '@financial-times/workspace-menu';
import { init as nDefinitionsInit } from '@financial-times/n-definitions/client';
import * as playArticle from './components/play-article/main';
import { init as browsableListsInit } from '@financial-times/n-browsable-lists/main';

// Ads
import * as ads from './components/ads';
import * as adsUtils from '../utils/ads';

const clientOpts = [];

// Page Kit
import * as pageKitLayout from '@financial-times/dotcom-ui-layout';
import * as pageKitFlags from '@financial-times/dotcom-ui-flags';
import * as pageKitAppContext from '@financial-times/dotcom-ui-app-context';

// Tracking
import * as nTracking from '@financial-times/n-tracking';

import * as nFeedback from '@financial-times/n-feedback';
import * as nSyndication from '@financial-times/n-syndication';

import readyState from 'ready-state';
import replaceWithFlourish from './components/flourish/replace-graphics';
import scrollytelling from './components/scrollytelling';

// In Article Content Sign Up
import oOverlay from '@financial-times/o-overlay';
import signUpConfirmation from './components/sign-up/confirmation';
import {
	addSignupDetailsListeners,
	registerOnLoadHandler
} from './components/sign-up/details';

// Google Extended Access
import { getUserAndAccessExtendedInfo } from './helpers/google-extended-access';
import { isUserExtendedAccessEligible } from '../server/share/google-extended-access';

import { initSharingTooltip } from './components/enterprise-share/tooltip';
import { initEnterpriseSurveys } from './components/enterprise-surveys/qualtrics-survey';
import followPlusInstantAlerts from '@financial-times/n-myft-ui/components/jsx/follow-plus-instant-alerts';
import preferencesModal from '@financial-times/n-myft-ui/components/jsx/preferences-modal';

const SIGN_UP_CONFIRMATION_OVERLAY_ID = 'signUpConfirmationOverlay';

readyState.dom.then(() => {
	const flags = pageKitFlags.init();
	const appContext = pageKitAppContext.init();

	let oTracking;
	const headerOptions = flags.get('enhancedSearchTest')
		? { enhancedSearchUrl: 'https://enhanced-search.ft.com/?q=', searchState: 'open' }
		: {};

	pageKitLayout.init({ headerOptions });

	if (flags.get('oTracking')) {
		oTracking = nTracking.init({ appContext: appContext.getAll() });
		componentTracking(oTracking);
	}

	if (flags.get('definitionFeature')) {
		const article = appContext.get('contentType') === 'article';
		const scrollytellingElements = document.querySelector(
			'[data-component="scrollytelling-image"]'
		);
		if (article && !scrollytellingElements) {
			nDefinitionsInit();
		}
	}

	if (flags.get('enterpriseSharingTooltipsAndLabels')) {
		initSharingTooltip();
	}

	if (flags.get('podcastReferral')) {
		playArticle.init();
	}

	//getting rootId to pass it to the subscription sign-up iframe to be consistent between root_id
	const trackingRootId = oTracking.getRootID() || null;
	const signUpArticleIframe = document.getElementById('signUpIframe');
	if (signUpArticleIframe) {
		const url = new URL(
			signUpArticleIframe.getAttribute('data-prev-url'),
			window.location.origin
		);
		if (trackingRootId) {
			url.searchParams.append('trackingRootId', trackingRootId);
		}
		signUpArticleIframe.setAttribute('src', url.href);
	}

	if (flags.get('qualtrics')) {
		nFeedback.init({ ...appContext.getAll() });
	}

	if (flags.get('realUserMonitoringForPerformance')) {
		nTracking.trackers.realUserMonitoringForPerformance();
	}

	if (flags.get('optimizely') && flags.get('optimizelyArticle')) {
		// The Optimizely snippet attaches relevant experiment details to the window object

		let i = 0;
		const getActiveTests = () => {
			i++;
			const activeTests =
				window.FT &&
				window.FT.optimizelyAdditionalDetails &&
				window.FT.optimizelyAdditionalDetails.spoorTracking;
			if (activeTests) {
				activeTests.forEach((test) => {
					nTracking.broadcast('oTracking.event', {
						category: 'optimizely',
						action: 'experiment',
						...test
					});
				});
				clearInterval(intervalId);
			}
			if (i >= 5) {
				nTracking.broadcast('oTracking.event', {
					category: 'optimizely',
					action: 'no-experiment'
				});
				clearInterval(intervalId);
			}
		};
		const intervalId = setInterval(getActiveTests, 100);
	}

	if (flags.get('syndication')) {
		nSyndication.init(flags);
	}

	if (flags.get('myFtApi')) {
		clientOpts.push({ relationship: 'followed', type: 'concept' });
		clientOpts.push({ relationship: 'saved', type: 'content' });
	}

	myftUi.client.init(clientOpts).catch((e) => {
		// Keeps console clean for anonymous users
		if (e !== 'No session cookie found') {
			throw e;
		}
	});

	if (flags.get('articleShareButtons')) {
		share.init(flags);
	}

	workspaceNavInit({
		app: appContext.appName || 'article',
		enabled: !flags.get('myFtWorkspaceTest') && flags.get('enterpriseWorkspaceNav'),
		navTarget: '[data-component="nav-list--right"]',
		drawerTarget: '[data-component="drawer-menu--primary__drawer-menu-list"]',
		drawerDividerTarget: '[data-component="drawer-menu-item--divide"]'
	}).catch((error) => {
		// eslint-disable-next-line no-console
		console.error(
			'Workspace navigation failed to initialise, please inform LifeCycle team in Enterprise',
			error
		);
	});

	//strip out the imageId query parameter so it doesn't get duplicated when sharing graphics again
	if (
		window.location.search &&
		window.location.search.indexOf('imageId=') >= 0
	) {
		window.history.replaceState(
			null,
			null,
			window.location.pathname +
				window.location.search.replace(
					/&imageId(=[^&]*)?|^\?imageId(=[^&]*)?&?/,
					''
				)
		);
	}

	nTracking.trackers.pageAttention({ target: '.article__content' });

	if (flags.get('textCopyTracking')) {
		nTracking.trackers.textCopying();
	}

	legalCopy(flags);

	if (flags.get('enterpriseUserAnnotations')) {
		const initOptions = {
			readOnly: !flags.get('enterpriseUserAnnotationsEditor'),
			highlightsRelatedContent: flags.get('highlightsRelatedContent')
		};
		import('@financial-times/user-annotations-editor').then(
			({ default: userAnnotationsEditor }) => {
				userAnnotationsEditor.init(initOptions);
			});
	}

	seriesStickyNav.init();

	if (document.querySelector('.o-expander__toggle')) {
		Array.from(
			document.body.querySelectorAll('[data-o-component="o-expander"]'),
			(rootEl) => {
				const userOptions = Expander._getDataAttributes(rootEl);

				const opts = Object.assign(userOptions, {
					selectors: {
						toggle: '.o-expander__toggle',
						content: '.o-expander__content',
						item: userOptions.itemSelector || 'li'
					},
					classnames: {
						initialized: 'o-expander--initialized',
						inactive: 'o-expander--inactive',
						expanded: 'o-expander__content--expanded-next-article',
						collapsed: 'o-expander__content--collapsed',
						collapsibleItem: 'o-expander__collapsible-item'
					}
				});

				return Expander.createCustom(rootEl, opts);
			}
		);
	}

	myftUi.ui.init({
		anonymous: !/FTSession=/.test(document.cookie),
		flags
	});

	if (flags.get('myFTInstantAlertsOnboarding')) {
		followPlusInstantAlerts();
		preferencesModal();
	}
	
	oDate.init();

	[...document.querySelectorAll('[data-o-component="o-video"]')].forEach(
		(videoEl) => {
			new Video(videoEl, {
				advertising: flags.get('ads') && flags.get('videoPlayerAdvertising'),
				placeholder: true,
				optimumwidth: 700,
				optimumvideowidth: 1280,
				systemcode: 'next-article'
			});
		}
	);
	// nClip supports the usage of content from ElasticSearch. Temporarily used in case of a failure in the Content Pipeline API.
	// It will be removed once the Content Pipeline API is considered fully stable and fallback to ES won't be anymore a possible option.
	nClip.init(null, {
		fallbackImage: flags.get('clipsFallbackFeature') !== 'variant'
	});
	Clip.init(null, {
		fallbackImage: flags.get('clipsFallbackFeature') !== 'variant'
	});

	// video anchors are added for core experience only
	// remove video anchors when JS is enabled
	document
		.querySelectorAll('a[data-asset-type="video"][data-embedded="true"]')
		.forEach((videoAnchorEl) => {
			videoAnchorEl.parentNode.removeChild(videoAnchorEl);
		});

	[...document.querySelectorAll('[data-o-component="o-audio"]')].forEach(
		(audioEl) => {
			new Audio(audioEl);
		}
	);

	const isSignedIn = document.cookie.includes('FTSession');

	if (isSignedIn) {
		magnetInit(flags)
			.then(() => {
				const replacedItem = document.querySelector('.js-instant-alert-cta');
				replacedItem.style.display = 'none';
			})
			.catch((err) => {
				// fail silently
				// eslint-disable-next-line no-console
				console.debug('failed to init magnet', err);
			});
	}

	if (
		flags.get('articleComments') &&
		document.querySelector('#o-comments-stream')
	) {
		commentsInit(flags);
	}

	if (nExponea.canShowExponea(flags)) {
		nExponea.init();
	}

	if (
		!flags.get('adsDisableInternalCMP') &&
		(flags.get('messageSlotBottom') || flags.get('messageSlotTop'))
	) {
		nMessagingClient.init();
	}

	if (flags.get('articleRelatedContent')) {
		onwardJourney.init(flags).catch((err) => oErrors.report(err));
	}

	if (document.querySelector('.twitter-tweet')) {
		initialiseEmbeddedTweets();
	}

	progressIndicator();

	if (flags.get('onboardingMessaging') === 'followPlusEmailDigestTooltip') {
		onboardingTooltipInit();
	}

	new Feedback('article-summary');

	if (flags.get('articleInstantAlertsPromo')) {
		preBodyPromotionalBanner();
	}

	if (flags.get('myftArticleInstantAlertsCta')) {
		instantAlertCta();
	}

	scrollytelling(flags);

	if (flags.get('useFlourish')) {
		replaceWithFlourish(appContext);
	}

	// Initialise ads last to minimise risk of interference from 3rd party scripts
	if (flags.get('ads')) {
		// Skip initialising legacy OAds if we're using PG
		if (adsUtils.adsUsePGClient(flags) === false) {
			ads.init(appContext, flags);
		}
	}

	initLiveBlogPost();

	initTables();

	printButton.init();

	// In article content sign up confirmation popup using origami o-overlay component
	const pageOverlays = oOverlay.init();
	const signUpConfirmationOverlay = pageOverlays.find(
		(overlay) => overlay.id === SIGN_UP_CONFIRMATION_OVERLAY_ID
	);

	if (signUpConfirmationOverlay) {
		// render the popup
		signUpConfirmationOverlay.open();

		// attaching submit button behaviour after the overlay ends rendering
		document.addEventListener('oOverlay.ready', (overlayReadyEvent) => {
			if (overlayReadyEvent.detail.el.id === SIGN_UP_CONFIRMATION_OVERLAY_ID) {
				signUpConfirmation(signUpConfirmationOverlay);
			}
		});
	}

	if (flags.get('inArticleContentSignUp')) {
		registerOnLoadHandler();
		addSignupDetailsListeners();
	}

	if (flags.get('enterpriseSurveys')) {
		try {
			initEnterpriseSurveys();
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(
				'Enterprise Survey failed to initialise please report to Enterprise Services and Tools. ',
				e
			);
		}
	}

	if (flags.get('amplitude_browsable-lists')) {
		browsableListsInit({
			amplitudeExperiment: true,
			parentSelector: '.article__right-bottom'
		});
	}

	// Google Extended Access initialization
	if (isUserExtendedAccessEligible(window.location.search, flags.flags)) {
		getUserAndAccessExtendedInfo(appContext.appContext)
			.then(({ userState, showcaseEntitlement }) =>
				window.InitGaaMetering(userState, showcaseEntitlement)
			)
			.catch((err) => oErrors.report(err));
	}
});
