/**
 * @param {{
 *   pos: number;
 *   slotY: number;
 *   isFullbleed: boolean;
 * }} props
 * @returns {string}
 */
function getSlotHTML({ pos, slotY, isFullbleed }) {
	return `
	<div
		class="responsive advert sidebar-advert sidebar-advert--fullbleed"
		style="top: ${slotY}px;"
		data-o-ads-targeting="pos=mid${pos || ''};"
		data-o-ads-name="${isFullbleed ? '' : 'non-'}full-bleed-rhr-ad-${pos}"
		data-o-ads-formats-default="false"
		data-o-ads-formats-small="false"
		data-o-ads-formats-medium="false"
		data-o-ads-formats-large="HalfPage,MediumRectangle,Responsive,OneByOne"
		data-o-ads-formats-extra="HalfPage,MediumRectangle,Responsive,OneByOne"
		aria-hidden="true">
	</div>
	`;
}

/**
 * @param {{
 *   startPos: number;   // The offset from which to start numbering pos values
 *   slotYs: number[];   // The y-coordinates of the slots
 *   isFullbleed: boolean; // whether the article has full-bleed image
 *   maxPos: number; // maximum number of ad positions to render
 * }} props
 * @returns {string}
 */
export function getSlotsHTML({
	startPos = 0,
	slotYs,
	isFullbleed,
	maxPos = Infinity
}) {
	let slotHtml = '';
	for (const slotY of slotYs) {
		if (startPos > maxPos) break;
		if (startPos === 2) startPos++; // ensure there is always ad with pos=mid1 and pos=mid3

		slotHtml += getSlotHTML({ pos: startPos++, slotY, isFullbleed });
	}

	return slotHtml;
}
