const FLOURISH_DOMAINS = ['https://flo.uri.sh', 'https://public.flourish.studio'];

let flourishMessageEventListener;

function init(cb) {
  if (!customFlourishAnalytics.isInitialised) {
    customFlourishAnalytics.isInitialised = true;
    flourishMessageEventListener = window.addEventListener(
      'message',
      (event) => {
        if (FLOURISH_DOMAINS.includes(event.origin) && event.data) {
          const eventData = JSON.parse(event.data);
          if (eventData.method === 'ftCustomAnalytics') {
            eventData.context.url = document.URL;
            eventData.context.referrer = document.referrer;
            delete eventData.method;
            cb(eventData);
          }
        }
      },
      false
    );
  }
}

function destroy() {
  window.removeEventListener('message', flourishMessageEventListener);
  customFlourishAnalytics.isInitialised = false;
}

const customFlourishAnalytics = {
  isInitialised: false,
  destroy,
  init,
};

export default customFlourishAnalytics;
