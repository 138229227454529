import myftClient from 'next-myft-client';
import getToken from '@financial-times/n-myft-ui/myft/ui/lib/get-csrf-token';
import {
	isAnonymousUser,
	getRedirectUrlForAnonymousUser
} from '../utils/instant-alert-cta';

const closeCta = (element) => {
	element.classList.add('instant-alert-cta--hidden');
};

const enableAlert = (button) => {
	if (isAnonymousUser(document)) {
		const conceptId = button.getAttribute('data-concept-id');
		const splitedUrl = window.location.href.split('/');
		const contentId = splitedUrl.length
			? splitedUrl[splitedUrl.length - 1]
			: '';

		return window.location.assign(
			getRedirectUrlForAnonymousUser(conceptId, contentId)
		);
	}

	const csrfToken = getToken();
	myftClient
		.add(
			'user',
			null,
			'followed',
			'concept',
			button.getAttribute('data-concept-id'),
			{ token: csrfToken, _rel: { instant: true } }
		)
		.then(() => {
			button.setAttribute('disabled', '');
			button.innerHTML = 'Enabled';
		});
};

export default () => {
	const enableButton = document.querySelector('.instant-alert-cta__enable');
	const cta = document.querySelector('.instant-alert-cta');

	if (cta) {
		const conceptId = cta.getAttribute('data-concept-id');

		// Hide the CTA if the user already has subscribed to instant alerts
		document.body.addEventListener(
			'myft.user.followed.concept.load',
			(event) => {
				const hasInstantAlert = event.detail.items.find(
					(concept) => concept.uuid === conceptId && concept._rel.instant
				);
				if (hasInstantAlert) {
					closeCta(cta);
				}
			}
		);

		// Hide the CTA if the user already has subscribed to the daily digest
		document.body.addEventListener(
			'myft.user.preferred.preference.load',
			(event) => {
				const hasDailyDigest = event.detail.items.find(
					(preference) => preference.uuid === 'email-digest'
				);
				if (hasDailyDigest) {
					closeCta(cta);
				}
			}
		);
	}

	if (enableButton) {
		enableButton.onclick = () => enableAlert(enableButton);
	}
};
