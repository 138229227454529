import oOverlay from '@financial-times/o-overlay';
import { broadcast } from 'n-ui-foundations';

const SIGN_UP_ADDITIONAL_CONFIRMATION_OVERLAY_ID =
	'signUpAdditionalConfirmationOverlay';

const topicsTrackingProps = {
	buttonType: 'topics',
	action: 'topics-added',
	formType: 'next.register',
	type: 'in-article'
};
const newslettersTrackingProps = (newsletterName) => ({
	buttonType: 'newsletters',
	action: 'newsletter-added',
	formType: 'next.newsletter',
	type: newsletterName
});

function openAdditionalConfirmationModal() {
	const allOverlays = oOverlay.getOverlays();
	const additionalConfirmation =
		allOverlays[SIGN_UP_ADDITIONAL_CONFIRMATION_OVERLAY_ID];
	additionalConfirmation.open();
}

export function displayAdditionalConfirmation(newsletter) {
	const trackingProps = newsletter
		? newslettersTrackingProps(newsletter)
		: topicsTrackingProps;

	broadcast('oTracking.event', {
		action: trackingProps.action,
		category: 'signup',
		app: 'subscribe',
		formType: trackingProps.formType,
		type: trackingProps.type
	});

	openAdditionalConfirmationModal();
}
