import Overlay from '@financial-times/o-overlay';

const onwardJourneyDetails = {
	apple: {
		href: 'https://itunes.apple.com/app/apple-store/id1200842933?pt=246269&ct=apps-tour&mt=8',
		img: {
			src: 'https://www.ft.com/__assets/creatives/tour/apps/ios-download.svg',
			alt: 'Download from the iOS Apps store'
		}
	},
	google: {
		href: 'https://play.google.com/store/apps/details?id=com.ft.news&referrer=utm_source%3DPodcast%26utm_medium%3DListen%2520Article%2520Banner%26anid%3Dadmob',
		img: {
			src: 'https://www.ft.com/__origami/service/image/v2/images/raw/https%253A%252F%252Fwww.ft.com%252F__assets%252Fcreatives%252Ftour%252Fapps%252Fgoogle-play-badge-3x.png?source=next-article',
			alt: 'Download on Google Play'
		}
	}
};

function getDeviceType() {
	if (/\b(iPhone|iPad)\b/i.test(navigator.userAgent)) {
		return 'apple';
	} else if (/\b(Android)\b/i.test(navigator.userAgent)) {
		return 'google';
	} else {
		return 'desktop';
	}
}

function getOverlayContent() {
	const deviceType = getDeviceType();
	const overlayMarkup = `
		<button class='play-article-overlay__close o-overlay__close' aria-label='close overlay' data-trackable='article-overlay-close'></button>
		<div class='play-article-overlay__img'></div>
		<small class='play-article-overlay__title'>The FT in your pocket </small>
		<strong class='play-article-overlay__main'>Listen to this article</strong>
		<div class='play-article-overlay__divider'></div>
		<p class='play-article-overlay__text'>Get more from your podcasts with audio articles. Only in the FT app.</p>
		${
			deviceType === 'desktop'
				? `<a class='play-article-overlay__onward-link--desktop' href='https://www.ft.com/tour/apps') data-trackable='ft-tour-app'>Learn more about the app</a>`
				: `<a class='play-article-overlay__onward-link' href=${onwardJourneyDetails[deviceType].href} data-trackable='${deviceType}-store-download' target="_blank" role="link">
			<img src=${onwardJourneyDetails[deviceType].img.src} alt='${onwardJourneyDetails[deviceType].img.alt}' height="46"><span class='play-article-overlay__onward-link--tracking-text'>download app store</span</a>`
		}
	`;
	return overlayMarkup.trim();
}

function createFakeDoorOverlay() {
	const overlayContent = getOverlayContent();
	new Overlay('play-article-overlay', {
		html: overlayContent,
		trigger: '.play-article__button',
		modal: true,
		class: 'play-article-overlay',
		parentnode: 'play-article',
		customclose: true
	});
}

function createPlayArticleContainer() {
	const container = document.createElement('div');
	container.setAttribute('class', 'play-article');
	const sectionMarkUp = `
		<button class='play-article__button' aria-label='listen to this article' data-trackable='article-play' text='listen to this article'></button>
		<span>
            <p class='play-article__text'>Listen to this article</p>
        </span>`;
	container.innerHTML = sectionMarkUp.trim();
	const introPage = document.querySelector('.package-onward--intropage');
	const articleInfo = document.querySelector('.article-info');

	if (!introPage && articleInfo) {
		articleInfo.insertAdjacentElement('afterend', container);
	}
	createFakeDoorOverlay();
}

export function init() {
	createPlayArticleContainer();
}
