/* eslint-disable no-console */

export function log() {
	console.log('⛳️', ...arguments);
}

export function getSlot() {
	const slot = document.createElement('div');
	slot.classList.add('responsive', 'advert', 'sidebar-advert');
	slot.dataset.oAdsTargeting = 'pos=com';
	slot.dataset.oAdsFormatsSmall = 'false';
	slot.dataset.oAdsFormatsLarge =
		'HalfPage,MediumRectangle,Responsive,OneByOne';
	slot.ariaHidden = 'true';
	slot.style.display = 'none';

	return slot;
}

/**
 * Find & store selector targets or throw if any are null
 * - `rhr`: required
 * - `comments`: will be null if comments are disabled
 *
 * @template {Record<string, string>} T
 * @param {T} selectors
 */
export function findSelectorTargets(selectors) {
	/**
	 * @typedef {keyof T} SelectorKey
	 * @typedef {[SelectorKey, string][]} SelectorTuples
	 * @typedef {Record<SelectorKey, HTMLElement>} ElementDict
	 */

	const entries = /** @type {SelectorTuples} */ (Object.entries(selectors));
	const els = /** @type {ElementDict} */ ({});
	for (const [key, selector] of entries) {
		const el = document.querySelector(selector);
		if (!el) {
			throw new Error(`selectors.${String(key)} not found: bailing early`);
		}

		els[key] = /** @type {HTMLElement} */ (el);
	}

	return els;
}
