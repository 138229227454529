import * as tracking from './tracking';

import count from '../comments/count';
import OComments from '@financial-times/o-comments';

export function init() {
	const commentsElement = document.getElementById('o-comments-stream');
	const countElement = document.getElementById('o-comments-count');

	count(countElement);
	OComments.init(commentsElement);

	commentsElement.addEventListener('oComments.loginPrompt', () => {
		const currentPath = new URL(location.href).pathname;
		const commentsJumpAnchor = '#comments-anchor';

		location.href = `https://www.ft.com/login?location=${encodeURIComponent(
			currentPath
		)}${encodeURIComponent(commentsJumpAnchor)}`;
	});

	tracking.init();
}
