import session from 'next-session-client';

export async function getUserAndAccessExtendedInfo(appContext) {
	const url = new URL(
		`https://www.ft.com/buy/api/user-state-access/${appContext.contentId}`
	);
	url.search = window.location.search;

	const response = await fetch(url, {
		method: 'GET',
		headers: {
			'Content-type': 'application/json',
			FTSession_s: session.sessionId()
		}
	});

	if (response.status !== 200) {
		throw new Error(
			`Error getting User and Access Extended Info. Error ${response.status} - ${response.statusText}`
		);
	}

	return response.json();
}
