export default function audioTeaserView(oTracking, selector) {
	// Send an event when an audio teaser enters the viewport. This is used to help calculate
	// the click-through-rate. Speak to Luke K or the audio team.
	oTracking.view.init({
		selector,
		getContextData: (el) => ({
			componentContentId: el.getAttribute('data-id'),
			component: 'teaser',
			type: 'audio',
			subtype: 'podcast' // only podcast is audio subtype at 03/2019. Need to change when audio has more subtypes.
		})
	});
}
