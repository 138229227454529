/**
 * @typedef {import("@typings/app").ConfigMap } ConfigMap
 */

import readyState from 'ready-state';

import * as utils from './utils';
import { dimensions } from './dimensions';
import { getSlotsHTML } from './slot';
import { blogIndexes } from './blogIndexes';

export const DATA_ARTICLE_ATTRIBUTE = {
	fullbleed: 'full-width-graphics',
	nonFullbleed: 'no-full-width-graphics',
	liveBlog: 'live-blog'
};

export const contentElSelectors = Object.values(DATA_ARTICLE_ATTRIBUTE)
	.map((articleName) => `[data-article-type=${articleName}]`)
	.join(',');

const rhrAdsClassName = 'sidebar-advert--fullbleed';
const bottomRHRClassName = 'rhr__ads--lower';

/**
 * @param {{
 *	targetRect: DOMRect;
 *	intersectingRects: DOMRect[];
 * }} props
 *
 * @returns {number[]}
 */
export function calculateSlotYs({
	targetRect,
	intersectingRects: candidateRects
}) {
	const imgRects = utils.calcIntersections({ candidateRects, targetRect });
	const regions = utils.calcAdRegions({ imgRects, targetRect, dimensions });
	const slotYs = utils.calcSlotYs({ regions, dimensions });

	return slotYs;
}

/**
 * Shortlist slotYs if adsRightRailExtraPositions is false (we dont need that many ads slots)
 * @param {{
 *   slotYs: number[];
 *   adsRightRailExtraPositions: boolean;
 *   isBottomRHR: boolean;
 * }} props
 * @returns {number[]}
 */
function shortlistSlotYs({ slotYs, adsRightRailExtraPositions, isBottomRHR }) {
	if (adsRightRailExtraPositions) return slotYs; // return all available slots if adsRightRailExtraPositions = true
	return isBottomRHR
		? slotYs.length < 2
			? slotYs
			: [slotYs[0], slotYs[slotYs.length - 1]] // bottom RHR, need 2 slots for mid1 and mid3
		: [slotYs[0]]; // top RHR, need 1 slot for mid
}

/**
 * Client-side-only approach
 * 1. Generate positioned slots
 * 2. Initialise all, since they are guaranteed to be valid
 *
 * @param {{
 *   targetEl: HTMLDivElement?;
 *   intersectingRects: DOMRect[];
 *   initSlot: (slot: ChildNode) => void;
 *   adsObserver: IntersectionObserver?;
 *   options: {
 *     isFullbleed: boolean;
 *     adsRightRailExtraPositions: boolean;
 * 	 	 maxPos: number;
 *   }
 * }} props
 * @returns {number} the next "x" for pos=mid(x)
 */
function initialiseRHRTop({
	targetEl,
	intersectingRects,
	initSlot,
	adsObserver,
	options = {
		isFullbleed: false,
		adsRightRailExtraPositions: false,
		maxPos: Infinity
	}
}) {
	if (!targetEl) return 0;
	let { isFullbleed, adsRightRailExtraPositions, maxPos } = options;

	const targetRect = targetEl.getBoundingClientRect();
	const slotYs = shortlistSlotYs({
		slotYs: calculateSlotYs({ targetRect, intersectingRects }),
		adsRightRailExtraPositions,
		isBottomRHR: false
	});

	// render ads slot
	targetEl.innerHTML = getSlotsHTML({
		startPos: 0,
		slotYs,
		isFullbleed,
		maxPos
	});
	for (const slotEl of targetEl.children) {
		initSlot(slotEl);
		adsObserver && adsObserver.observe(slotEl);
	}

	// return the correct index for pos=mid in the bottom half of RHR
	// need to bump the value by one as pos=mid2 is skipped
	return slotYs.length >= 2 ? slotYs.length + 1 : slotYs.length;
}

/**
 * @param {HTMLDivElement} contentEl
 * @returns {HTMLDivElement}
 */
function createRHRBottomEl(contentEl) {
	const targetEl = document.createElement('div');
	targetEl.className = bottomRHRClassName;
	contentEl.appendChild(targetEl);

	return targetEl;
}

/**
 * B. Client-side-only approach
 * 1. Generate positioned slots
 * 2. Initialise all, since they are guaranteed to be valid
 *
 * @param {{
 *   targetEl: HTMLDivElement?;
 *   intersectingRects: DOMRect[];
 *   initSlot: (slot: ChildNode) => void;
 *   adsObserver: IntersectionObserver?;
 *   options: {
 *     isFullbleed: boolean; // whether full bleed images exist in the article
 *     adsRightRailExtraPositions: boolean; // the flag showing if extra ads slots are need
 *     initPosMidX: number;  // the x for mid(x) to start with (default 4)
 * 	   maxPos: number; // the max position to render (default 4)
 *   };
 * }} props
 * @return {void}
 */
export function initialiseRHRBottom({
	targetEl,
	intersectingRects,
	initSlot,
	adsObserver,
	options = {
		isFullbleed: false,
		adsRightRailExtraPositions: false,
		initPosMidX: 4,
		maxPos: Infinity
	}
}) {
	if (!targetEl) return;
	let { isFullbleed, adsRightRailExtraPositions, initPosMidX, maxPos } =
		options;
	initPosMidX = initPosMidX || 4;

	const targetRect = targetEl.getBoundingClientRect();
	const slotYs = shortlistSlotYs({
		slotYs: calculateSlotYs({ targetRect, intersectingRects }),
		adsRightRailExtraPositions,
		isBottomRHR: true
	});

	targetEl.innerHTML = getSlotsHTML({
		startPos: initPosMidX,
		slotYs,
		isFullbleed,
		maxPos
	});
	for (const slotEl of targetEl.children) {
		initSlot(slotEl);
		adsObserver && adsObserver.observe(slotEl);
	}
}

/**
 * Client-side-only approach
 * 1. Generate positioned slots based on position of live blogs
 * 2. Initialise all, since they are guaranteed to be valid
 *
 * @param {{
 *   targetEl: HTMLDivElement?;
 *   liveblogRects: DOMRect[];
 *   blogIndexes: number[];
 *   initSlot: (slot: ChildNode) => void;
 *   options: {
 *     adsRightRailExtraPositions: boolean; // the flag showing if extra ads slots are need,
 * 	 maxPos: number; // the max position to render (default 4)
 *   }
 * }} props
 * @returns {void}
 */
export function initialiseRHRLiveblog({
	targetEl,
	liveblogRects,
	blogIndexes,
	initSlot,
	options = { adsRightRailExtraPositions: false, maxPos: Infinity }
}) {
	if (targetEl && liveblogRects) {
		const { adsRightRailExtraPositions, maxPos } = options;

		const targetRect = targetEl.getBoundingClientRect();
		let slotYs = utils.calcSlotYsLiveblog({
			targetRect,
			liveblogRects,
			blogIndexes
		});
		if (!adsRightRailExtraPositions) {
			// only add mid1 and mid3 to live blog when extra slots are needed
			slotYs = [slotYs[0]];
		}

		// render ads slot
		targetEl.innerHTML = getSlotsHTML({
			startPos: 0,
			slotYs,
			isFullbleed: false,
			maxPos
		});
		for (const slotEl of targetEl.childNodes) {
			initSlot(slotEl);
		}
	}
}

/**
 * Create the intersection observer for ads slots in RHR
 * @param {{
 *   getIntersectingRects: () => DOMRect[];
 *   observerOptions: {
 *     root: Element | Document | null;
 *     rootMargin: string;
 *     threshold: number | number[];
 *   },
 *   adsRightRailExtraPositions: boolean;
 * }} props
 * @returns {IntersectionObserver?}
 */
function createAdsObserver({
	getIntersectingRects,
	observerOptions,
	adsRightRailExtraPositions
}) {
	/**
	 * @param {IntersectionObserverEntry[]} events
	 */
	const observerCallback = (events) => {
		for (const event of events) {
			const adsEl = event.target;
			// only check RHR ads slot entering the viewport
			if (!(event.isIntersecting && adsEl.className.includes(rhrAdsClassName)))
				continue;
			const intersectingRects = getIntersectingRects();
			const overlapRects = utils.calcIntersections({
				candidateRects: intersectingRects,
				targetRect: adsEl.getBoundingClientRect()
			});
			// in case there are overlaps (with probably fullbleed images),
			// recalculate the Y values of every ads slots
			if (overlapRects.length > 0) {
				const targetEl = adsEl.parentElement;
				const isBottomRHR = targetEl.classList.contains(bottomRHRClassName);
				const targetRect = targetEl.getBoundingClientRect();
				const slotYs = shortlistSlotYs({
					slotYs: calculateSlotYs({ targetRect, intersectingRects }),
					adsRightRailExtraPositions,
					isBottomRHR
				});

				const adsEls = targetEl.getElementsByClassName(rhrAdsClassName);
				for (let i = 0; i < adsEls.length; i++) {
					if (i >= slotYs.length) {
						// no space for ads, remove it
						adsEls[i].remove();
					} else {
						// otherwise move it to new position
						adsEls[i].style.top = `${slotYs[i]}px`;
					}
				}
				return; // stop all furthur checking after moving everything
			}
		}
	};
	return window.IntersectionObserver
		? new IntersectionObserver(observerCallback, observerOptions)
		: null;
}

/**
 * @param {object} props
 * @param {boolean} props.isUserLoggedIn
 * @param {HTMLDivElement | null} props.contentEl
 * @param {(slot: ChildNode) => void} props.initSlot
 * @param {ConfigMap} props.flags
 *
 * @returns {void}
 */
export function initialiseRHR({ initSlot, contentEl, isUserLoggedIn }) {
	if (!contentEl) return;

	// whether the article is a fullbleed or a live blog is checked with data-article-type attribute
	const isFullbleed =
		contentEl.dataset.articleType === DATA_ARTICLE_ATTRIBUTE.fullbleed;
	const isLiveblog =
		contentEl.dataset.articleType === DATA_ARTICLE_ATTRIBUTE.liveBlog;
	const adsRightRailExtraPositions = !isUserLoggedIn;
	const MAX_RHR_ADS = 4;

	// TODO Add a resize listener if page initially rendered small and resized
	const breakpoint = window.matchMedia('(min-width: 980px)');
	if (breakpoint.matches === false) return;

	// Defer execution to allow images to fully load, required to calculate intersections
	readyState.complete.then(() => {
		if (isLiveblog) {
			// live blog, mid at top, mid1 at post 4, mid3 at post8
			const liveblogRects = Array.from(
				contentEl.querySelectorAll('.x-live-blog-post')
			).map((el) => el.getBoundingClientRect());
			initialiseRHRLiveblog({
				liveblogRects,
				blogIndexes,
				targetEl: contentEl.querySelector('.article__right'),
				initSlot,
				options: {
					adsRightRailExtraPositions,
					maxPos: MAX_RHR_ADS
				}
			});
		} else {
			// normal article
			const intersectingElSelectors = [
				'[data-layout-width="full-grid"]',
				'[data-layout-width="full-bleed"]',
				'.article__right-bottom .concept-list',
				'.browsable-lists'
			];
			/**
			 * @returns {DOMRect[]}
			 */
			const getIntersectingRects = () => {
				return utils.getIntersectingRects(contentEl, intersectingElSelectors);
			};
			const adsObserver = createAdsObserver({
				getIntersectingRects,
				observerOptions: {
					root: null,
					rootMargin: '0px 0px 0px 0px',
					threshold: [0]
				},
				adsRightRailExtraPositions
			});
			const initPosMidX = initialiseRHRTop({
				intersectingRects: getIntersectingRects(),
				targetEl: contentEl.querySelector('.article__right'),
				initSlot,
				adsObserver,
				options: {
					isFullbleed,
					adsRightRailExtraPositions,
					maxPos: MAX_RHR_ADS
				}
			});
			initialiseRHRBottom({
				intersectingRects: getIntersectingRects(),
				targetEl: createRHRBottomEl(contentEl),
				initSlot,
				adsObserver,
				options: {
					isFullbleed,
					adsRightRailExtraPositions,
					initPosMidX,
					maxPos: MAX_RHR_ADS
				}
			});
		}
	});
}
