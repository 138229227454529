/**
 * @typedef {{getCount: (articleId: string) => Promise<number>}} OComments
 */

import { findSelectorTargets, getSlot, log } from './utils';

/**
 * @param {Object} props
 * @param {OComments} props.Comments
 * @param {(slot: HTMLDivElement) => void} props.initSlot
 */
export function init({ Comments, initSlot }) {
	try {
		const els = findSelectorTargets({
			rhr: '.comments-rhr',
			comments: '#o-comments-stream'
		});
		let mySlot = getSlot();
		els.rhr.appendChild(mySlot);
		initSlot(mySlot);
		/** @param {Event} event */
		async function onCommentsReady({ target }) {
			// @ts-ignore
			const articleId = target.dataset.oCommentsArticleId;
			const commentCount = await Comments.getCount(articleId);

			if (commentCount > 0) {
				mySlot.style.display = 'block';
			}
		}

		document.addEventListener('oComments.ready', onCommentsReady, {
			once: true
		});

		return els;
	} catch (error) {
		// @ts-ignore
		log(error.message);
	}
}
