import { $$ } from 'n-ui-foundations';
import OShare from '@financial-times/o-share';
import hideWhenOverlapped from '../hide-when-overlapped/main';
import { shareArticleModalOverlay } from '../gift-article';

export function init(flags = {}) {
	const contentEls = $$(
		'[data-layout-width="full-grid"],[data-layout-width="full-bleed"]'
	);

	const uxImprovementFlag =
		flags.articleSharingUxUpdates === 'variant' ? true : false;

	$$(
		'#site-content [data-o-component=o-share][data-o-share-article]:not([data-o-share--js])'
	).forEach((shareElement) => {
		new OShare(shareElement);
		// Hide the vertical share nav if it overlaps any content elements.
		const verticalShareWrapper = shareElement.closest('.share-nav__vertical');
		if (verticalShareWrapper) {
			hideWhenOverlapped(verticalShareWrapper, contentEls);
		}
	});

	[...document.querySelectorAll('.js-gift-article')].forEach(
		(clickedElement) => {
			clickedElement.addEventListener('click', () => {
				shareArticleModalOverlay({ clickedElement, uxImprovementFlag });
			});
		}
	);
}
