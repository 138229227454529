import queryString from 'query-string';
import session from 'next-session-client';

export default async function lureClient(articleId, flags) {
	if (!articleId) {
		return Promise.reject('no article id');
	}

	const data = {
		slots: 'onward,onward2'
	};

	if (flags.get('lureMyFtRecommendations')) {
		const { uuid } = await session.uuid();
		if (uuid) {
			data.userId = uuid;
		}
	}

	const url = `/lure/v2/content/${articleId}?${queryString.stringify(data)}`;

	return fetch(url, {
		// need cookie to get personalised or ab-tested responses
		credentials: 'include'
	}).then((res) => res.json());
}
