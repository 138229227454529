import { render, h } from 'preact';
import {
	classNameExists,
	getArticleId,
	getArticleUrl,
	getTitle,
	isFreeArticle
} from '../utils/article';
import session from 'next-session-client';
import { ShareArticleModal } from '@financial-times/x-gift-article';
import * as nTracking from '@financial-times/n-tracking';

const LARGE_SCREEN_BREAKPOINT = 980;
const ENTERPRISE_ARTICLE_OVERLAY_ID = '80bef6c3-6bb3-48f8-ad98-f7ad7d876df0';

export const shareArticleModalOverlay = ({ uxImprovementFlag }) => {
	nTracking.broadcast('oTracking.event', {
		action: 'view',
		category: 'component',
		app: 'article',
		product: 'next',
		component: {
			id: ENTERPRISE_ARTICLE_OVERLAY_ID,
			type: 'overlay',
			name: 'enterprise-article-overlay'
		}
	});

	const props = {
		isFreeArticle: isFreeArticle(),
		article: {
			url: getArticleUrl(),
			title: getTitle(),
			id: getArticleId()
		},
		sessionId: session.sessionId(),
		showMobileShareLinks: true,
		isArticleSharingUxUpdates: uxImprovementFlag,
		actionsRef(actions) {
			if (actions) {
				actions.activate();
				actions.checkIfHasHighlights();
			}
		},
		enterpriseApiBaseUrl: 'https://enterprise-sharing-api.ft.com',
		hasHighlights: classNameExists('user-annotation')
	};

	const container = getShareArticleContainer();

	if (container) {
		const shareArticleModal = <ShareArticleModal {...props} />;
		render(shareArticleModal, container);

		const shareArticleModalElement = container.firstElementChild;

		if (shareArticleModalElement) {
			shareArticleModalElement.setAttribute(
				'data-free-article',
				props.isFreeArticle.toString()
			);

			shareArticleModalElement.setAttribute('id', 'share-article-modal');

			handleClose();
		}
	}
};

const getShareArticleContainer = () => {
	const isMobile =
		Math.max(
			document.documentElement.clientWidth || 0,
			window.innerWidth || 0
		) <= LARGE_SCREEN_BREAKPOINT;

	return document.getElementById(
		`share-modal-${isMobile ? 'horizontal' : 'vertical'}`
	);
};

/**
 *  @param {MouseEvent} e
 */
const modalClickOutsideHandler = (e) => {
	const container = getShareArticleContainer();
	const target = e.target instanceof Element && e.target;

	if (target && container) {
		// We don't want to close the modal if the user clicked on the share button or the modal itself.
		const clickWasInsideShareButton = target.closest(
			'#share-button-vertical, #share-button-horizontal'
		);

		const clickWasInsideShareModal = e.composedPath().includes(container);

		// Otherwise, we close the modal.
		const shouldCloseModal =
			!clickWasInsideShareButton && !clickWasInsideShareModal;

		if (shouldCloseModal) {
			render(null, container);
		}
	}
};

const handleClose = () => {
	// Remove existing handler if previously added.
	document.removeEventListener('click', modalClickOutsideHandler);

	// This handler is used to close the share modal when the user clicks outside of it.
	document.addEventListener('click', modalClickOutsideHandler);

	const shareArticleModalCloseButton = document.querySelector(
		'#share-article-modal .o-overlay__close'
	);

	shareArticleModalCloseButton?.addEventListener('click', () => {
		const container = getShareArticleContainer();

		if (container) {
			render(null, container);
		}
	});
};
