import { render } from 'preact';
import { broadcast } from 'n-ui-foundations';
import { getArticleId } from '../utils/article';
import LazyLoad from '@financial-times/o-lazy-load';
import lureClient from './lure-client';
import simpleTemplate from '../../../pages/onward-journey/simple.jsx';
import { backFillNativeAdSlot } from '../ads';
import { adsUsePGClient } from '../../../utils/ads';

let bottomSlotContainer;
let components;

/**
 *
 * @param {{
 * 	bottomSlotContainer: Node
 * 	displayNativeAds: boolean
 * 	adsUsePG: boolean
 * }} params
 */
function setOnwardJourneyComponents({
	bottomSlotContainer,
	displayNativeAds,
	adsUsePG
}) {
	components = {
		onward: {
			container: bottomSlotContainer,
			template: simpleTemplate,
			appendAd: displayNativeAds,
			hasRendered: false,
			trackingName: 'bottom',
			minItems: 2,
			maxItems: 4,
			adsUsePG
		},
		onward2: {
			container: bottomSlotContainer,
			template: simpleTemplate,
			hasRendered: false,
			trackingName: 'bottom2',
			minItems: 2,
			maxItems: 4,
			adsUsePG
		}
	};
}

function cleanup() {
	if (
		bottomSlotContainer &&
		!components.onward.hasRendered &&
		!components.onward2.hasRendered
	) {
		bottomSlotContainer.parentNode.removeChild(bottomSlotContainer);
	}
}

export function init(flags) {
	bottomSlotContainer = document.querySelector(
		'[data-component="onward-journey__bottom"]'
	);
	if (!bottomSlotContainer) {
		return Promise.resolve();
	}

	setOnwardJourneyComponents({
		bottomSlotContainer,
		displayNativeAds: flags.get('nativeAds'),
		adsUsePG: adsUsePGClient(flags)
	});

	return lureClient(getArticleId(), flags)
		.then(({ onward, onward2, _metadata = {} } = {}) => {
			if (onward) {
				const numRows = 2;
				components.onward.maxItems = numRows * 4 /* 4 items per row */;
				onward.title = onward.title || 'Read next';
			}

			renderComponent(onward, components.onward);
			renderComponent(onward2, components.onward2);

			// Remove container elements as soon as possible
			// so they don't have visible height.
			cleanup();

			new LazyLoad(document.body);
			broadcast('asyncContentLoaded');

			if (_metadata.contentSelection) {
				Object.keys(_metadata.contentSelection).forEach((key) => {
					const hasRendered = key in components && components[key].hasRendered;

					if (!hasRendered) {
						delete _metadata.contentSelection[key];
					} else {
						// Correct the coponent name sent by Lure API.
						// There's a mismatch between the API's Slot names and the tracking names given to the UI components.
						// This is needs refactoring after the current A/B test is complete ... and not before, otherwise
						// tracking data will be messed up.
						_metadata.contentSelection[components[key].trackingName] =
							_metadata.contentSelection[key];
						delete _metadata.contentSelection[key];
					}
				});
			}

			broadcast('oTracking.event', {
				action: 'component-mounted',
				category: 'onward-journey',
				detail: {
					..._metadata
				}
			});
		})
		.catch((error) => {
			cleanup();
			throw error;
		});
}

function renderComponent(data, component) {
	const { container, containerClass, template, appendAd, trackingName } =
		component;

	if (!container || !data || !data.items) {
		return;
	}

	const items = data.items.slice(0, component.maxItems);

	if (items.length < component.minItems) {
		return;
	}

	if (containerClass) {
		container.classList.add(containerClass);
	}

	let adPosition = 0;
	const rowLength = 4;

	if (appendAd) {
		const ad = { type: 'ad' };
		if (items.length < rowLength) {
			adPosition = items.push(ad);
		} else {
			adPosition = rowLength;
			items.splice(adPosition - 1, 0, ad);
		}
	}

	render(template({ ...data, items, trackingName, container }), container);

	const element = document.getElementsByClassName('onward-journey__items')[0];

	component.hasRendered = true;

	if (adPosition) {
		// We load an extra article teaser and hide it so that it can be
		// shown in case there is no native ad to show.
		const lastOnwardTeaserEl = element.querySelector(
			`.onward-journey__item:nth-of-type(${items.length})`
		);
		const nativeAdEl = element.querySelector(
			`.onward-journey__item:nth-of-type(${adPosition})`
		);

		if (lastOnwardTeaserEl) {
			lastOnwardTeaserEl.style.display = 'none';
		}

		if (nativeAdEl && items.length - 1 >= rowLength) {
			nativeAdEl.style.marginTop = '0';
		}

		backFillNativeAdSlot(nativeAdEl, lastOnwardTeaserEl);
	}
}
