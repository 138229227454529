'use strict';

//TODO - It might be better to embed this in the HTML rather than hard-code it here??
const tsAndCsLink = 'https://help.ft.com/help/legal-privacy/terms-conditions/';
const copyrightLink =
	'https://help.ft.com/help/legal-privacy/copyright/copyright-policy/';
const supportEmail = 'licensing@ft.com';
const tourLink = 'https://www.ft.com/tour';
const ftLink = 'https://www.ft.com';

const msgText = `Please use the sharing tools found via the share button at the top or side of articles. Copying articles to share with others is a breach of FT.com T&Cs and Copyright Policy. Email licensing@ft.com to buy additional rights. Subscribers may share up to 10 or 20 articles per month using the gift article service. More information can be found at ${tourLink}.`;
const msgHtml = `Please use the sharing tools found via the share button at the top or side of articles. Copying articles to share with others is a breach of
<a href="${ftLink}">FT.com</a> <a href="${tsAndCsLink}">T&Cs</a> and <a href="${copyrightLink}">Copyright Policy</a>. Email <a href="mailto:${supportEmail}">${supportEmail}</a> to buy additional rights. Subscribers may share up to 10 or 20 articles per month using the gift article service. More information can be <a href="${tourLink}">found here</a>.`;

const supportsClipboard = (e) => e.clipboardData || window.clipboardData;
const hasEventClipboardSupport = (e) =>
	!!(e.clipboardData && e.clipboardData.setData);
const getSelectionText = () => window.getSelection().toString();
const countWords = (text) => text.split(/\s+/).length;

let altPressed = false;
const ALT = 18;

function setClipboard(e, html, text) {
	if (hasEventClipboardSupport(e)) {
		e.clipboardData.setData('text/html', html);
		e.clipboardData.setData('text/plain', text);
	} else {
		window.clipboardData.setData('Text', text);
	}

	e.preventDefault();
}

function getHTMLMessage(selectedText) {
	return `${msgHtml} <br> <a href="${location.href}">${location.href}</a><br><br> ${selectedText}`;
}

function getTextMessage(selectedText) {
	return `
	${msgText}
	${location.href}

	${selectedText}
`;
}

function onCopy(e) {
	if (!supportsClipboard(e)) {
		return;
	}

	const selectedText = getSelectionText();
	const wordCount = countWords(selectedText);

	if (wordCount > 30) {
		const text = getTextMessage(selectedText);
		const html = getHTMLMessage(selectedText);
		setClipboard(e, html, text);
	}
}

export default function legalCopy() {
	// Helps detect copy event without interfering with Text-to-speech software
	document.body.addEventListener('keydown', interceptKeys);
	document.body.addEventListener('copy', handleCopy);
}

// Alt+Esc triggers 'copy' event in chrome 56 and under
// A lot of text-to-speech software relies on these keys to read out text to the user.
function interceptKeys(e) {
	// Remember if alt was pressed
	if (e.keyCode === ALT) {
		altPressed = true;

		// Clean up after 10 seconds
		setTimeout(function () {
			altPressed = false;
		}, 10000);
	}
}

// Trigger onCopy only if alt hasn't been pressed immediately prior
function handleCopy(e) {
	if (!altPressed) {
		onCopy(e);
	}
	altPressed = false;
}
