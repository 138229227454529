export function init() {
	const seriesNavStatic = document.querySelector('.package__nav--top');
	const ftHeaderSticky = document.querySelector('[data-o-header--sticky]');

	if (seriesNavStatic && ftHeaderSticky) {
		ftHeaderSticky.innerHTML = seriesNavStatic.outerHTML;

		// remove focus states from links and buttons in duplicated hidden nav markup
		const buttons = ftHeaderSticky.querySelectorAll('button');
		const links = ftHeaderSticky.querySelectorAll('a');
		Array.from(buttons).forEach((button) => {
			button.tabIndex = -1;
		});
		Array.from(links).forEach((link) => {
			link.tabIndex = -1;
		});
	}

	getPackageExpanders().forEach(synchroniseExpansion);
}

function getPackageExpanders(opts) {
	const expanders = [...document.querySelectorAll('[data-package-expander]')];
	const except = opts && opts.except;
	return except ? expanders.filter((x) => x !== except) : expanders;
}

function synchroniseExpansion(expander) {
	expander.addEventListener('oExpander.expand', (event) => {
		getPackageExpanders({ except: event.target }).forEach(expand);
	});
	expander.addEventListener('oExpander.collapse', (event) => {
		getPackageExpanders({ except: event.target }).forEach(collapse);
	});
}

function expand(el) {
	setState(el, 'expanded');
}

function collapse(el) {
	setState(el, 'collapsed');
}

function setState(el, state) {
	const toggle = el.querySelector('.o-expander__toggle');
	const content = el.querySelector('.o-expander__content');
	toggle.setAttribute(
		'aria-expanded',
		state === 'collapsed' ? 'false' : 'true'
	);
	content.setAttribute('aria-hidden', state === 'collapsed' ? 'true' : 'false');
}
