const triggerPrint = (event) => {
	event.preventDefault();
	window.print();
};

const init = () => {
	const printIconElement = document.querySelector(
		'[data-component="print-button"]'
	);

	if (printIconElement) {
		printIconElement.addEventListener('click', triggerPrint);
	}
};

export { init };
