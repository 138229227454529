import myftClient from 'next-myft-client';
import getToken from '@financial-times/n-myft-ui/myft/ui/lib/get-csrf-token';
import superstore from 'superstore-sync';
import { broadcast } from 'n-ui-foundations';
import {
	isAnonymousUser,
	getRedirectUrlForAnonymousUser,
	createDismissPromoCallback
} from '../utils/instant-alert-cta';

let originalButtonLabel = 'Get instant email alerts';

const toggleState = (hasInstantAlert, enableButton) => {
	enableButton.classList[hasInstantAlert ? 'add' : 'remove'](
		'instant-alerts-promo-button--enabled',
		'o-buttons-icon--tick'
	);
	enableButton.innerHTML = hasInstantAlert ? 'Subscribed' : originalButtonLabel;
	enableButton.value = String(!hasInstantAlert);
	enableButton.setAttribute('aria-pressed', String(hasInstantAlert));
};

const createOnConceptsLoadCallback = (conceptId, enableButton) => {
	return function (event) {
		const hasInstantAlert = event.detail.items.find(
			(concept) => concept.uuid === conceptId && concept._rel.instant
		);
		toggleState(!!hasInstantAlert, enableButton);
	};
};

const createOnClickCallback = (conceptId, enableButton) => {
	return function (event) {
		event.preventDefault();
		const isEnabled = enableButton.classList.contains(
			'instant-alerts-promo-button--enabled'
		);

		if (isAnonymousUser(document)) {
			const splitedUrl = window.location.href.split('/');
			const contentId = splitedUrl.length
				? splitedUrl[splitedUrl.length - 1]
				: '';

			return window.location.assign(
				getRedirectUrlForAnonymousUser(conceptId, contentId)
			);
		}

		return myftClient
			.add('user', null, 'followed', 'concept', conceptId, {
				token: getToken(),
				_rel: { instant: !isEnabled }
			})
			.then(() => toggleState(!isEnabled, enableButton));
	};
};

export default () => {
	const promo = document.querySelector('.js-instant-alert-promo');
	if (!promo) return;

	const enableButton = promo.querySelector('button');
	if (enableButton) {
		originalButtonLabel =
			enableButton.getAttribute('title') ||
			enableButton.dataset.originalTitle ||
			originalButtonLabel;
	}
	const conceptId = enableButton && enableButton.dataset.conceptId;
	const promoName = promo.dataset.promoName;
	const promoDismissedStorageKey = `${promoName}-promo-dismissed-key`;
	const dismissPromo = createDismissPromoCallback(
		promo,
		promoDismissedStorageKey
	);

	const hasDismissed = superstore.local.get(promoDismissedStorageKey);
	if (hasDismissed) return dismissPromo();

	broadcast('oTracking.event', {
		action: `${promoName}-promo-shown`,
		category: 'instant-alerts'
	});

	document.body.addEventListener(
		'myft.user.followed.concept.load',
		createOnConceptsLoadCallback(conceptId, enableButton)
	);
	enableButton.addEventListener(
		'click',
		createOnClickCallback(conceptId, enableButton)
	);
};
