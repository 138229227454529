import oDate from '@financial-times/o-date';
import oVideo from '@financial-times/o-video';

export function after(el) {
	oDate.init(el);

	Array.from(el.querySelectorAll('.video-container-ftvideo .o-video')).forEach(
		(videoEl) => {
			new oVideo(videoEl, {
				placeholder: false,
				systemcode: 'next-article'
			});
		}
	);
}
