import { h } from '@financial-times/x-engine';
import React, { useEffect } from 'react';

const initSlot = (name) => {
	if (!window.oAds?.slots[name]) {
		return window.oAds?.slots?.initSlot(name);
	}
};

/**
 * @param {object} props
 * @param {string} props.name
 * @param {string} props.classNames
 * @param {string} props.nativeFormat
 * @param {boolean} props.adsUsePG
 */

export default (props) => {
	const { name, classNames, nativeFormat, adsUsePG } = props;

	if (adsUsePG) {
		return <pg-slot data-config-key={name}></pg-slot>;
	}

	useEffect(() => {
		if (window.oAds?.isInitialised) {
			initSlot(name);
		} else {
			document.addEventListener('oAds.initialised', () => {
				initSlot(name);
			});
		}
	});

	return (
		<div
			className={classNames}
			data-o-ads-name={name}
			data-o-ads-targeting={`pos=native;format=${nativeFormat};appname=article`}
			data-o-ads-formats="PartnerContent"
		/>
	);
};
