import oTooltip from '@financial-times/o-tooltip';

const MESSAGING_ID = 'add-to-my-ft-tooltip';
const TRACKING_CATEGORY = 'n-messaging';

const dispatchEvent = (name, detail) => {
	document.body.dispatchEvent(
		new CustomEvent(name, {
			detail,
			bubbles: true
		})
	);
};

const addFollowButtonTooltip = (myFtFollowButton) => {
	const FLASH_ANIMATION_CLASS = 'experimental-myft-flash';
	const primaryTag = document.querySelector(
		'[data-trackable="primary-theme"]'
	).innerHTML;

	const tooltip = new oTooltip(myFtFollowButton, {
		target: 'add-to-my-ft',
		content: `Never miss a story on ${primaryTag}. Get the latest headlines in a Daily Digest email.`,
		showOnConstruction: true,
		position: 'right'
	});

	tooltip.tooltipEl.addEventListener('oTooltip.close', () => {
		dispatchEvent('oTracking.event', {
			category: TRACKING_CATEGORY,
			action: 'close',
			messaging: MESSAGING_ID
		});
	});

	myFtFollowButton.addEventListener('click', () => {
		dispatchEvent('oTracking.event', {
			category: TRACKING_CATEGORY,
			action: 'act',
			messaging: MESSAGING_ID
		});

		tooltip.close('', '', false);
		document.body.classList.remove(FLASH_ANIMATION_CLASS);
		document.body.classList.add(FLASH_ANIMATION_CLASS);
	});
};

export function init(flags) {
	const myFtFollowButton = document.querySelector(
		'.o-topper__tags .n-myft-follow-button.n-myft-follow-button--standard[aria-pressed="false"]'
	);

	if (myFtFollowButton) {
		addFollowButtonTooltip(myFtFollowButton, flags);
		dispatchEvent('oTracking.event', {
			category: TRACKING_CATEGORY,
			action: 'view',
			messaging: MESSAGING_ID
		});
	}
}
