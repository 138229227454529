export default (container, elementsToAvoid) => {
	let observer;

	const reinitialise = () => {
		if (observer) {
			observer.disconnect();
			observer = undefined;
		}
		init();
	};

	const init = () => {
		if (elementsToAvoid.length) {
			// removes existing listener (if any)
			document.body.removeEventListener('FLOURISH_LOADED', reinitialise);

			observeContentElements();

			document.body.addEventListener('FLOURISH_LOADED', reinitialise);
		}
	};

	const observeContentElements = () => {
		observer = new IntersectionObserver(handleIntersection);

		elementsToAvoid.forEach((contentEl) => {
			observer.observe(contentEl);
		});
	};

	let currentElementHidingBehind;

	const handleIntersection = (events) => {
		const enteringElement = events.find((event) => event.isIntersecting);

		// Only show share nav if this show event has the same target element as the most recent hide event
		const shouldShowShareNav = currentElementHidingBehind
			? events.find((event) => event.target === currentElementHidingBehind)
			: false;

		if (enteringElement) {
			container.classList.add('data-overlap-initialised');
			/*
			 * Keep track of the element the share nav entered causing the share nav to be hidden
			 * Allows us to only show it again when its exiting the element from the most recent hide event
			 * This helps when 2 full bleed elements come very close together and the hide event comes before show event for the previous element
			 */
			currentElementHidingBehind = enteringElement.target;
		} else {
			if (shouldShowShareNav) {
				container.classList.remove('data-overlap-initialised');
			}
		}
	};

	init();
};
