export default () => {
	const el = document.querySelector('[data-share-nav-vertical=true]');

	// display|hide the watch ad slot when the article content is in view
	window.addEventListener(
		'article:scroll-progress',
		({ detail: { midPercentage } = {} } = {}) => {
			const visibility = midPercentage ? 'visible' : 'hidden';
			const opacity = midPercentage ? 1 : 0;
			const watchElement = document.querySelector('.watch');

			if (watchElement) {
				watchElement.setAttribute(
					'style',
					`visibility: ${visibility}; opacity: ${opacity}`
				);
			}
		}
	);

	if (el) {
		el.insertAdjacentHTML(
			'beforeend',
			`<div
				class="responsive advert advert--watch"
				data-o-ads-targeting="pos=watch;"
				data-o-ads-name="watch-ad-slot"
				data-o-ads-formats-default="false"
				data-o-ads-formats-small="false"
				data-o-ads-formats-medium="false"
				data-o-ads-formats-large="ShareNav,Responsive"
				data-o-ads-formats-extra="ShareNav,Responsive"
				aria-hidden="true">
			</div>
			`
		);
	}
};
