import { h, Component } from 'preact';

export default class ProgressIndicator extends Component {
	render() {
		const { progress = 0, isRead } = this.props;
		const complete = isRead || progress === 100;
		const classNames = ['progress-indicator'];

		if (progress === 0) classNames.push('progress-indicator--no-progress');
		if (progress > 0 && progress < 100)
			classNames.push('progress-indicator--in-progress');

		return (
			<div className="progress-indicator__container" aria-live="polite">
				<div className={classNames.join(' ')}>
					<div
						className="progress-indicator__slice"
						style={{ transform: `rotate(${(180 / 100) * progress}deg)` }}
					>
						<div
							className="progress-indicator__bar"
							style={{ transform: `rotate(${(180 / 100) * progress}deg)` }}
						/>
					</div>
					<div className="progress-indicator__slice">
						<div
							className="progress-indicator__bar"
							style={{
								transform: `rotate(${
									(180 / 100) * Math.min(progress * 2, 100)
								}deg)`
							}}
						/>
					</div>
					<div className="progress-indicator__overlay">
						{complete && (
							<span className="progress-indicator__icon--complete" />
						)}
					</div>
					<div className="o-normalise-visually-hidden">
						current progress {Math.round(progress)}%
					</div>
				</div>
			</div>
		);
	}
}
