import Expander from '@financial-times/o-expander';
import {
	addTopicsToLoggedUser,
	signupNewslettersToLoggedUser
} from '../../helpers/user-sign-up';
import { displayAdditionalConfirmation } from '../sign-up/confirmation-messages';

function getAllCheckBoxes() {
	const overlay = document.querySelector('.sign-up-confirmation-overlay');
	return overlay
		? Array.from(overlay.querySelectorAll('input[type=checkbox]'))
		: [];
}

function getSelectedSuggestionIds() {
	return getAllCheckBoxes()
		.filter((suggestion) => suggestion.checked)
		.map((suggestion) => suggestion.id);
}

function addTopicsOnClickCallback(signUpConfirmationOverlay) {
	return function () {
		const selectedTopicIds = getSelectedSuggestionIds();
		if (selectedTopicIds && selectedTopicIds.length) {
			addTopicsToLoggedUser(selectedTopicIds);

			displayAdditionalConfirmation();
		} else {
			signUpConfirmationOverlay.close();
		}
	};
}

function signupNewslettersOnClickCallback(signUpConfirmationOverlay) {
	return function () {
		const selectedNewsletterIds = getSelectedSuggestionIds();
		if (selectedNewsletterIds && selectedNewsletterIds.length) {
			signupNewslettersToLoggedUser(selectedNewsletterIds);

			const overlaySubtitle = signUpConfirmationOverlay.content.querySelector(
				'.sign-up-confirmation-overlay-subtitle'
			);
			const newsletterName = overlaySubtitle
				? overlaySubtitle.dataset.newsletterName
				: '';
			displayAdditionalConfirmation(newsletterName);
		} else {
			signUpConfirmationOverlay.close();
		}
	};
}

function getSignupOnclickCallback(signUpConfirmationOverlay, submitButton) {
	if (submitButton.classList.contains('signup-newsletters')) {
		return signupNewslettersOnClickCallback(signUpConfirmationOverlay);
	}

	return addTopicsOnClickCallback(signUpConfirmationOverlay);
}

function enableOrDisableButtonCallback(button) {
	return async function () {
		const selectedSuggestions = getSelectedSuggestionIds();
		if (selectedSuggestions && selectedSuggestions.length) {
			button.disabled = false;
		} else {
			button.disabled = true;
		}
	};
}

export default (signUpConfirmationOverlay) => {
	if (!signUpConfirmationOverlay) return;

	const submitButton = signUpConfirmationOverlay.content.querySelector(
		'button.o-buttons--primary'
	);
	submitButton.addEventListener(
		'click',
		getSignupOnclickCallback(signUpConfirmationOverlay, submitButton)
	);

	if (submitButton.classList.contains('signup-newsletters')) {
		const newsletterId = submitButton.dataset.newsletterId;
		signupNewslettersToLoggedUser([newsletterId]);
	}

	getAllCheckBoxes().forEach((checkbox) =>
		checkbox.addEventListener(
			'change',
			enableOrDisableButtonCallback(submitButton)
		)
	);

	const closeButton =
		signUpConfirmationOverlay.context.querySelector('.o-overlay__close');
	closeButton.setAttribute('data-trackable', 'signup-confirmation-close');

	const expanderElements =
		signUpConfirmationOverlay.content.querySelectorAll('.o-expander');
	if (expanderElements && expanderElements.length) {
		Expander.init();
	}
};
