import audioTeaserView from './audio-teaser-view';
import trackCustomFlourishGraphics from './custom-flourish-graphics';

const components = [
	{ selector: '[data-image-type="graphic"]', name: 'graphic' },
	{ selector: '[data-trackable="magnet-cta"]', name: 'magnet-slot' }
];

export default (oTracking) => {
	components.forEach((component) => {
		const isGraphicComponent =
			component.selector === '[data-image-type="graphic"]';
		oTracking.view.init({
			selector: component.selector,
			category: 'component',
			action: 'view',
			context: isGraphicComponent
				? {
						component: component.name
				  }
				: undefined,
			getContextData: !isGraphicComponent
				? () => {
						return {
							component: {
								name: component.name,
								type: 'container'
							}
						};
				  }
				: undefined
		});
	});

	audioTeaserView(oTracking, '.o-teaser--audio');
	trackCustomFlourishGraphics();
};
